/* eslint-disable react/jsx-curly-brace-presence */
// eslint-disable-next-line
import React from 'react';
import {
  CodeSnippet,
  Table,
  TableColumn,
  WarningPanel,
  Link,
  InfoCard,
} from '@backstage/core-components';
import { ContainerVulnerabilitiesServiceListWithEntity } from '../../metrics';
import { useContainerVulnerabilitiesSummary } from '../../hooks/useContainerVulnerabilitiesSummary';
import { format } from 'date-fns';
import { calculateWindowOfExposure } from '../../lib/chart/calculateWindowOfExposure';

const columns: TableColumn[] = [
  {
    title: 'Service',
    field: 'name',
    highlight: true,
    width: '16%',
    render: data => {
      const { name: entityName, id } =
        data as ContainerVulnerabilitiesServiceListWithEntity;
      return (
        <Link
          key={id}
          variant="body2"
          to={`/catalog/default/component/${entityName}/vulnerabilities`}
          data-testid={`entity-name-${entityName}`}
        >
          {entityName}
        </Link>
      );
    },
  },
  {
    title: 'Owner',
    field: 'owner',
    width: '16%',
    render: data => {
      const { owner, id } =
        data as ContainerVulnerabilitiesServiceListWithEntity;

      return (
        <Link
          key={id}
          variant="body2"
          to={`/catalog/default/group/${owner}`}
          data-testid={`owner-${owner}`}
        >
          {owner}
        </Link>
      );
    },
  },
  {
    title: 'Low',
    field: 'low_count',
    width: '8%',
  },
  {
    title: 'Moderate',
    field: 'moderate_count',
    width: '8%',
  },
  {
    title: 'High',
    field: 'high_count',
    width: '8%',
  },
  {
    title: 'Critical',
    field: 'critical_count',
    width: '8%',
  },
  {
    title: 'Total',
    field: 'vulnerability_count',
    width: '8%',
    defaultSort: 'desc',
  },
  {
    title: 'Exposure - Average (days)',
    field: 'mean_milliseconds',
    width: '14%',
    defaultSort: 'desc',
    render: data => {
      const { mean_milliseconds } =
        data as ContainerVulnerabilitiesServiceListWithEntity;

      return calculateWindowOfExposure(mean_milliseconds);
    },
  },
  {
    title: 'Exposure - 95th percentile (days)',
    field: 'percentile_95_milliseconds',
    width: '14%',
    defaultSort: 'desc',
    render: data => {
      const { percentile_95_milliseconds } =
        data as ContainerVulnerabilitiesServiceListWithEntity;

      return calculateWindowOfExposure(percentile_95_milliseconds);
    },
  },
];

type VulnerabilitiesTableProps = {
  variant?: 'gridItem';
};

const ContainerVulnerabilitiesTable = ({
  variant = 'gridItem',
}: VulnerabilitiesTableProps) => {
  const { isLoading, chartData, metricsApiError, entitiesError } =
    useContainerVulnerabilitiesSummary();

  const today = format(new Date(), 'MMM do y');

  if (metricsApiError || entitiesError) {
    return (
      <InfoCard variant={variant} title="Provided APIs">
        <WarningPanel
          severity="error"
          title="Could not load container service list API data"
          message={
            <CodeSnippet
              text={`${metricsApiError || entitiesError}`}
              language="text"
            />
          }
        />
      </InfoCard>
    );
  }

  return (
    <div data-testid="container-vulnerabilities-table">
      <Table
        isLoading={isLoading}
        columns={columns}
        options={{
          padding: 'dense',
          paging: true,
          search: false,
          pageSize: 20,
          actionsColumnIndex: -1,
          loadingType: 'linear',
          pageSizeOptions: [20, 50, 100],
          headerStyle: { textTransform: 'initial' },
        }}
        title="Open vulnerabilities by service"
        subtitle={`Service information for container vulnerabilities for ${today}`}
        data={chartData}
        totalCount={chartData.length ?? 0}
        style={{
          width: '100%',
        }}
      />
    </div>
  );
};

export { ContainerVulnerabilitiesTable };
