const targetRgbColour = '50,148,252';

export const commonTargetDatasetOptions = {
  fill: false,
  backgroundColor: `rgba(${targetRgbColour},0.4)`,
  borderColor: `rgba(${targetRgbColour},1)`,
  pointBorderWidth: 0,
  pointHoverRadius: 0,
  pointRadius: 0,
  pointHitRadius: 0,
  borderDash: [8, 3],
  order: 1,
};
