import { format, isToday } from 'date-fns';
import { transformContainerVulnerabilities } from './transformContainerVulnerabilities';
import { noDataMessagePlugin } from '../../lib/chart/noDataMessagePlugin';
import { buildDateAxisTickLabels } from '../../lib/chart/buildDateAxisTickLabels';

import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { ContainerVulnerabilitiesResult } from '../../metrics';
import { singularise } from '../../lib/singularise';

function containerVulnerabilitiesConfig(
  chartData: ContainerVulnerabilitiesResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformContainerVulnerabilities(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'bar',
    data,
    plugins: [noDataMessage],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          stacked: true,
          display: true,
          beginAtZero: true,
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildDateAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          stacked: true,
          display: true,
          min: 0,
          title: {
            display: true,
            text: 'Count',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          reverse: true,
          position: 'bottom',
          labels: {
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          callbacks: {
            title: items => {
              const totalVulnerabilityCount = items.reduce(
                (total, { formattedValue }) =>
                  total + parseInt(formattedValue, 10),
                0,
              );
              const firstItemRawData: any = items?.[0]?.raw;
              const { x: startDate } = firstItemRawData;

              if (isToday(startDate)) {
                return `Currently you have ${totalVulnerabilityCount} open ${singularise(
                  'vulnerabilities',
                  totalVulnerabilityCount,
                )}`;
              }

              return `On ${format(
                startDate,
                `EEEE do 'of' LLLL yyyy`,
              )} you had ${totalVulnerabilityCount} open ${singularise(
                'vulnerabilities',
                totalVulnerabilityCount,
              )}`;
            },
            label: item => {
              const datasetLabel = item?.dataset?.label?.toLowerCase();
              const vulnerabilityCount = item?.formattedValue;

              return ` ${vulnerabilityCount} ${datasetLabel}`;
            },
            labelColor: (context): any => ({
              borderColor: context?.dataset?.borderColor,
              backgroundColor: context?.dataset?.backgroundColor,
              borderWidth: 2,
            }),
          },
        },
      },
    },
  };
}

export { containerVulnerabilitiesConfig };
