/* eslint-disable react/jsx-curly-brace-presence */
// eslint-disable-next-line
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { makeStyles, useTheme } from '@material-ui/core';
import { MetricsSummaryChartContainer } from './MetricsSummaryChartContainer';
import { BackstageTheme } from '@backstage/theme';
import {
  CodeSnippet,
  InfoCard,
  Progress,
  WarningPanel,
} from '@backstage/core-components';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  metricsHeading: {
    fontSize: '22px',
    color: theme.palette.textContrast,
  },
  metricsChart: {
    backgroundColor: theme.palette.background.paper,
  },
  metricsProgress: {
    backgroundColor: 'transparent',
    border: 'none',
  },
  infoCard: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 'none',
  },
}));

function MetricsSummaryChart<T>({
  heading,
  descriptionLead,
  descriptionBody,
  variant = 'gridItem',
  chartData,
  error,
  configuration,
  chartName,
  currentValueMessage,
  dataTestId,
}: {
  heading: string;
  descriptionLead: string;
  descriptionBody: ReactNode;
  variant?: 'gridItem';
  chartData?: T;
  error?: Error;
  configuration: Function;
  chartName: string;
  currentValueMessage: ReactNode;
  dataTestId?: string;
}) {
  const classes = useStyles();
  const chartContainerRef = useRef<HTMLCanvasElement | null>(null);
  const { palette } = useTheme<BackstageTheme>();
  const [chartInstance, setChartInstance] = useState<Chart | null>(null);
  const [config, setConfig] = useState<any>();
  const chartDescription = `${heading} - ${chartName} - chart`;

  useEffect(
    function buildConfig() {
      if (chartData) {
        setConfig(configuration(chartData, palette));
      }
    },
    [chartData, configuration, palette],
  );

  useEffect(
    function tearDownAndSetupChart() {
      if (chartContainerRef?.current) {
        if (chartInstance) {
          chartInstance.destroy();
        }

        const newChartInstance = new Chart(chartContainerRef.current, config);
        setChartInstance(newChartInstance);
      }
    },
    [chartContainerRef, chartData, config], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <MetricsSummaryChartContainer
      heading={heading}
      descriptionLead={descriptionLead}
      descriptionBody={descriptionBody}
      currentValueMessage={currentValueMessage}
      dataTestId={dataTestId}
    >
      {!chartData && !error && (
        <InfoCard
          variant={variant}
          divider={false}
          className={classes.infoCard}
        >
          <Progress className={classes.metricsProgress} />
        </InfoCard>
      )}

      {error && (
        <InfoCard
          variant={variant}
          divider={false}
          className={classes.infoCard}
        >
          <WarningPanel
            severity="error"
            title={`Could not load ${heading} data from the Metrics API`}
            message={<CodeSnippet text={`${error}`} language="text" />}
          />
        </InfoCard>
      )}

      {chartData && (
        <canvas
          className={classes.metricsChart}
          ref={chartContainerRef}
          aria-label={chartDescription}
          aria-roledescription={chartDescription}
          role="graphics-document" // eslint-disable-line
        >
          <p>Sorry something has gone wrong!</p>
        </canvas>
      )}
    </MetricsSummaryChartContainer>
  );
}

export { MetricsSummaryChart };
