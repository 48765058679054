import { ChartData } from 'chart.js';
import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import { Deployments, DeploymentsResult } from '../../metrics';
import { parseISO } from 'date-fns';
import { buildWeekDataConfig } from '../../lib/chart/buildWeekDataConfig';
import { commonTargetDatasetOptions } from '../../lib/chart/commonTargetDatasetOptions';

const deploymentRgbColour = '255,119,46';
const deploymentRgbBorderColour = '238,110,43';

function transformDeployments({ deployments }: DeploymentsResult): ChartData {
  return buildWeekDataConfig<Deployments>(
    deployments,
    [buildDeploymentsDataset],
    buildServiceLevelObjectivesTargetDataset,
  );
}

function buildDeploymentsDataset(deployments: Deployments[]): any {
  const data = deployments.map(
    ({ week_number, week_starting, deployment_count }: Deployments) => {
      return {
        x: parseISO(week_starting),
        y: deployment_count,
        week_number,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Deployments',
    backgroundColor: `rgba(${deploymentRgbColour},0.3)`,
    borderColor: `rgba(${deploymentRgbBorderColour},1)`,
    pointBorderColor: `rgba(${deploymentRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${deploymentRgbColour},1)`,
    data,
  };
}

function buildServiceLevelObjectivesTargetDataset(deployments: Deployments[]) {
  return {
    ...commonTargetDatasetOptions,
    label: 'SLO',
    data: deployments.map(({ week_starting, service_level_objective }) => ({
      x: parseISO(week_starting),
      y: service_level_objective,
    })),
  };
}

export { transformDeployments };
