import React from 'react';
import { Navigate, Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { SearchPage } from '@backstage/plugin-search';
import { searchPage } from './components/search/SearchPage';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';
import { providers } from './identityProviders';
import * as plugins from './plugins';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/Brightness2';
import AlarmIcon from '@material-ui/icons/Alarm';
import { darkTheme, lightTheme } from './theme';
import {
  Metrics,
  Vulnerabilities,
  VulnerabilitiesContent,
} from '@internal/plugin-metrics';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { SWRConfig } from 'swr';
import { Analytics } from './components/Analytics';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { DevToolsPage } from '@backstage/plugin-devtools';

const Column = CatalogTable.columns;
const columns = [
  Column.createNameColumn(),
  Column.createOwnerColumn(),
  Column.createSpecTypeColumn(),
  Column.createSpecLifecycleColumn(),
  Column.createMetadataDescriptionColumn(),
  Column.createTagsColumn(),
];

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  icons: {
    alert: AlarmIcon,
  },
  components: {
    SignInPage: props => {
      return (
        <SignInPage
          {...props}
          providers={[...providers]}
          title="Sign-in"
          align="center"
        />
      );
    },
    ErrorBoundaryFallback: props => {
      return (
        <>
          <h1>Oops.</h1>
          <h2>
            The plugin {props?.plugin?.getId()} failed with{' '}
            {props.error.message}
          </h2>
          <button onClick={props.resetError}>Try again</button>
        </>
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: scaffolderPlugin.routes.root,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
  themes: [
    {
      id: 'light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={lightTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
    {
      id: 'dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={darkTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage columns={columns} />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/metrics" element={<Metrics />} />
    <Route path="/vulnerabilities" element={<Vulnerabilities />}>
      <VulnerabilitiesContent />
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/devtools" element={<DevToolsPage />} />
  </FlatRoutes>
);

const App = (props: any) => (
  <SWRConfig
    value={{
      refreshInterval: 600000, // refresh every 10 minutes
    }}
  >
    <AppProvider {...props}>
      <AlertDisplay />
      <AppRouter {...props}>
        <Analytics />
        <OAuthRequestDialog />
        <Root>{routes}</Root>
      </AppRouter>
    </AppProvider>
  </SWRConfig>
);

export default App;
