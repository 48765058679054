import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

declare global {
  interface Window {
    gtag: Function;
    dataLayer: (String | Object | Date)[];
  }
}

/**
 * See the README.md for an explanation of how Google Analytics setup works
 */
const usePageTracking = () => {
  const location = useLocation();
  const configApi = useApi(configApiRef);
  const googleAnalyticsTrackingId = configApi.getOptionalString(
    'app.googleAnalyticsTrackingId',
  );
  const [previousUrl, setPreviousUrl] = useState('');

  useEffect(
    function sendPageView() {
      const currentUrl = location.pathname;

      /* Two things of note here:
         1) We have a double render on Tabbed pages due to the way Helmet updates the document.title inside of
          BackStage, hence the previousUrl state check to stop double page title and views being sent
         2) Due to the document.title taking a little while to update via Helmet we wait a little while to send the
          page title and view
       */
      if (
        googleAnalyticsTrackingId &&
        previousUrl !== currentUrl &&
        window.gtag
      ) {
        setTimeout(() => {
          window.gtag('event', 'page_view', {
            page_title: document.title,
            page_path: location.pathname,
            send_to: googleAnalyticsTrackingId,
          });

          setPreviousUrl(currentUrl);
        }, 100);
      }
    },
    [location.pathname, googleAnalyticsTrackingId, previousUrl],
  );
};

const Analytics = () => {
  usePageTracking();

  return null;
};

export { Analytics };
