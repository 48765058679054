import { provideDateLabelParts } from './provideDateLabelParts';

function buildDateDataConfig<
  T extends { year: number; month: string; day: string },
>(
  chartData: T[],
  datasetTransformers: Function[],
  buildTargetIndicatorDataset?: Function,
) {
  const datasets = datasetTransformers.map(transformer =>
    transformer(chartData),
  );

  if (buildTargetIndicatorDataset) {
    const targetDataset = buildTargetIndicatorDataset(chartData);
    datasets.unshift(targetDataset);
  }

  return {
    labels: provideDateLabelParts<T>(chartData),
    datasets,
  };
}

export { buildDateDataConfig };
