// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { last } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { containerWindowOfExposureSummaryConfig } from './containerWindowOfExposureSummaryConfig';
import { ContainerWindowOfExposureAggregatedResult } from '../../../metrics';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { formatISO, sub } from 'date-fns';
import { MetricsSummaryChart } from '../../MetricsSummaryChart';
import { pluralise } from '../../../lib/pluralise';
import { calculateWindowOfExposure } from '../../../lib/chart/calculateWindowOfExposure';

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = () => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const projectName = configApi.getString('metrics.projectName');

  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the number of days since the vulnerability was discovered in
        the container or the number of days since a vulnerable container was
        first used. The number will rise every day for every container
        vulnerability that has not been resolved.
      </Typography>
      <Typography variant="body2" paragraph>
        More information about vulnerabilities and their severity may be found
        on the{' '}
        <Link
          to={`https://console.cloud.google.com/gcr/images/${projectName}`}
          target="_blank"
          rel="noopener"
        >
          GCP container registry console{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
        . For information per service please see the table below.
      </Typography>
    </>
  );
};

const ContainerWindowOfExposureSummary = () => {
  const [windowOfExposure, setWindowOfExposure] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi(metricsApiRef);
  const today = formatISO(new Date(), { representation: 'date' });
  const dateFrom = formatISO(sub(new Date(), { weeks: 1 }), {
    representation: 'date',
  });
  const dateTo = today;

  const { data: chartData, error } = useSWR(
    ['metrics-container-window-of-exposure-aggregated', dateFrom, dateTo],
    () =>
      api.getContainerWindowOfExposureAggregated({
        dateFrom,
        dateTo,
      }),
  );

  useEffect(
    function provideLoadingStatus() {
      if (!chartData && !error) {
        setIsLoading(true);
      }

      if (chartData || error) {
        setIsLoading(false);
      }
    },
    [chartData, error],
  );

  useEffect(
    function setCurrentWindowOfExposure() {
      const hasWindowOfExposure =
        chartData?.windowOfExposureAggregated.length ?? 0;

      if (hasWindowOfExposure) {
        const latestAggregatedWindowOfExposure = last(
          chartData?.windowOfExposureAggregated,
        );

        setWindowOfExposure(
          latestAggregatedWindowOfExposure?.mean_milliseconds ?? 0,
        );
      }
    },
    [chartData],
  );

  const windowOfExposureDays = calculateWindowOfExposure(windowOfExposure);

  const CurrentWindowOfExposureMessage = () => (
    <Typography variant="body2" color="textSecondary">
      {isLoading
        ? ''
        : `Average window of exposure is ${windowOfExposureDays} ${pluralise(
            'day',
            windowOfExposureDays.toString(),
          )}`}
    </Typography>
  );

  return (
    <MetricsSummaryChart<ContainerWindowOfExposureAggregatedResult>
      heading="Window of exposure"
      descriptionLead="How long are services exposed due to vulnerable containers?"
      descriptionBody={<Description />}
      chartData={chartData}
      error={error}
      configuration={containerWindowOfExposureSummaryConfig}
      chartName="aggregated"
      currentValueMessage={<CurrentWindowOfExposureMessage />}
      dataTestId="container-window-of-exposure-summary-chart"
    />
  );
};

export { ContainerWindowOfExposureSummary };
