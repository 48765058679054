import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import {
  ContainerVulnerabilities,
  ContainerVulnerabilitiesResult,
} from '../../metrics';
import { ChartData } from 'chart.js';
import { commonBarChartOptions } from '../../lib/chart/commonBarChartOptions';
import { buildDateDataConfig } from '../../lib/chart/buildDateDataConfig';

const criticalVulnerabilitiesRgbColour = '188,48,35';
const highVulnerabilitiesRgbColour = '238,110,43';
const moderateVulnerabilitiesRgbColour = '222,190,73';
const lowVulnerabilitiesRgbColour = '47,170,212';

function transformContainerVulnerabilities({
  openVulnerabilities,
}: ContainerVulnerabilitiesResult): ChartData {
  return buildDateDataConfig<ContainerVulnerabilities>(openVulnerabilities, [
    transformLowVulnerabilities,
    transformModerateVulnerabilities,
    transformHighVulnerabilities,
    transformCriticalVulnerabilities,
  ]);
}

function transformCriticalVulnerabilities(
  openVulnerabilities: ContainerVulnerabilities[],
) {
  const data = openVulnerabilities.map(
    ({ iso_date, critical_count }: ContainerVulnerabilities) => {
      return {
        x: new Date(iso_date),
        y: critical_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Critical',
    backgroundColor: `rgba(${criticalVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${criticalVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${criticalVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformHighVulnerabilities(
  openVulnerabilities: ContainerVulnerabilities[],
) {
  const data = openVulnerabilities.map(
    ({ iso_date, high_count }: ContainerVulnerabilities) => {
      return {
        x: new Date(iso_date),
        y: high_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'High',
    backgroundColor: `rgba(${highVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${highVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${highVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformModerateVulnerabilities(
  openVulnerabilities: ContainerVulnerabilities[],
) {
  const data = openVulnerabilities.map(
    ({ iso_date, moderate_count }: ContainerVulnerabilities) => {
      return {
        x: new Date(iso_date),
        y: moderate_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Moderate',
    backgroundColor: `rgba(${moderateVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${moderateVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${moderateVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformLowVulnerabilities(
  openVulnerabilities: ContainerVulnerabilities[],
) {
  const data = openVulnerabilities.map(
    ({ iso_date, low_count }: ContainerVulnerabilities) => {
      return {
        x: new Date(iso_date),
        y: low_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Low',
    backgroundColor: `rgba(${lowVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${lowVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${lowVulnerabilitiesRgbColour},1)`,
    data,
  };
}

export { transformContainerVulnerabilities };
