import qs from 'qs';
import { pickBy, identity } from 'lodash';
import { ConfigApi, IdentityApi } from '@backstage/core-plugin-api';
import { MetricsApi } from './MetricsApi';

import {
  LeadTimeResult,
  MeanTimeToResolutionAggregatedResult,
  MeanTimeToResolutionResult,
  ServiceAvailabilityResult,
  ServiceLatencyResult,
  QueryParams,
  DependencyVulnerabilitiesResult,
  DependencyWindowOfExposureResult,
  DependencyVulnerabilitiesAggregatedResult,
  DependencyRepositoryListResult,
  ContainerVulnerabilitiesResult,
  ContainerWindowOfExposureResult,
  ContainerVulnerabilitiesAggregatedResult,
  DependencyWindowOfExposureAggregatedResult,
  ContainerVulnerabilitiesServiceListResult,
  ContainerWindowOfExposureAggregatedResult,
  DeploymentsResult,
  SummaryMetricsResponse,
} from '../metrics';

export class MetricsClient implements MetricsApi {
  private readonly configApi: ConfigApi;
  private readonly identityApi: IdentityApi;

  constructor(options: { configApi: ConfigApi; identityApi: IdentityApi }) {
    this.configApi = options.configApi;
    this.identityApi = options.identityApi;
  }

  constructUrl(path: string, params?: QueryParams) {
    const apiGatewayUrl = this.getApiGatewayUrl();
    const paramsWithValues = pickBy(params, identity);
    const queryParamString = qs.stringify(paramsWithValues);

    return `${apiGatewayUrl}${path}${
      queryParamString ? `?${queryParamString}` : ''
    }`;
  }

  async authorisedFetch(url: string) {
    const { token } = await this.getIdToken();
    const response = await fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    });

    if (!response.ok) {
      throw new Error(
        `Get request failed to ${url} with ${response.status} ${response.statusText}`,
      );
    }

    return response;
  }

  async getLeadTime(
    serviceName: string,
    params?: QueryParams,
  ): Promise<LeadTimeResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/lead-time/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDeployments(
    serviceName: string,
    params?: QueryParams,
  ): Promise<DeploymentsResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/deployments/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getMeanTimeToResolution(
    serviceName: string,
    params?: QueryParams,
  ): Promise<MeanTimeToResolutionResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/mean-time-to-resolution/${serviceName}`,
      params,
    );

    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getMeanTimeToResolutionAggregated(
    serviceName: string,
    params?: QueryParams,
  ): Promise<MeanTimeToResolutionAggregatedResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/mean-time-to-resolution/${serviceName}/aggregated`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDependencyVulnerabilities(
    repoName: string,
    params?: QueryParams,
  ): Promise<DependencyVulnerabilitiesResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/vulnerabilities/open/${repoName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDependencyVulnerabilitiesAggregated(
    params?: QueryParams,
  ): Promise<DependencyVulnerabilitiesAggregatedResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/vulnerabilities/open',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getContainerVulnerabilities(
    serviceName: string,
    params?: QueryParams,
  ): Promise<ContainerVulnerabilitiesResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/container-vulnerabilities/open/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getContainerVulnerabilitiesAggregated(
    params?: QueryParams,
  ): Promise<ContainerVulnerabilitiesAggregatedResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/container-vulnerabilities/open',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getServiceAvailability(
    serviceName: string,
    params?: QueryParams,
  ): Promise<ServiceAvailabilityResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/service-availability/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getServiceLatency(
    serviceName: string,
    params?: QueryParams,
  ): Promise<ServiceLatencyResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/service-latency/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDependencyWindowOfExposure(
    repoName: string,
    params?: QueryParams,
  ): Promise<DependencyWindowOfExposureResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/vulnerabilities/window-of-exposure/${repoName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDependencyWindowOfExposureAggregated(
    params?: QueryParams,
  ): Promise<DependencyWindowOfExposureAggregatedResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/vulnerabilities/window-of-exposure',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getContainerWindowOfExposure(
    serviceName: string,
    params?: QueryParams,
  ): Promise<ContainerWindowOfExposureResult> {
    const url = this.constructUrl(
      `/platform/metrics/v1/container-vulnerabilities/window-of-exposure/${serviceName}`,
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getContainerWindowOfExposureAggregated(
    params?: QueryParams,
  ): Promise<ContainerWindowOfExposureAggregatedResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/container-vulnerabilities/window-of-exposure',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getDependencyVulnerabilitiesRepositoryList(
    params?: QueryParams,
  ): Promise<DependencyRepositoryListResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/vulnerabilities/repository/list',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  async getContainerVulnerabilitiesServiceList(
    params?: QueryParams,
  ): Promise<ContainerVulnerabilitiesServiceListResult> {
    const url = this.constructUrl(
      '/platform/metrics/v1/container-vulnerabilities/service/list',
      params,
    );
    const response = await this.authorisedFetch(url);

    return await response.json();
  }

  getIdToken(): Promise<{ token?: string | undefined }> {
    return this.identityApi.getCredentials();
  }

  getApiGatewayUrl(): string {
    return this.configApi.getString('metrics.apiGatewayUrl');
  }

  async getSummaryMetrics(
    params?: QueryParams,
  ): Promise<SummaryMetricsResponse> {
    const url = this.constructUrl(
      '/platform/metrics/v1/summary-metrics/summary-metrics',
      params,
    );
    const response = await this.authorisedFetch(url);
    return await response.json();
  }
}
