import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
// eslint-disable-next-line
import React, {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import TodayIcon from '@material-ui/icons/Today';
import { DateRanges } from '../../metrics';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { dateRangeConfig } from './dateRangeConfig';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  metricsToggleButtonGroup: {
    marginRight: '2px',
    marginTop: '2px',
    backgroundColor: 'transparent',
  },
  metricsToggleButton: {
    padding: '6px',
    borderRadius: 0,
    border: 0,
    backgroundColor: 'transparent !important',
    '&:disabled': {
      color: theme.palette.textContrast,
      backgroundColor: 'transparent',
    },
    '&:active, &:focus, &:hover': {
      color: theme.palette.textSubtle,
      backgroundColor: 'transparent',
    },
  },
  metricsToggleButtonFirst: {
    paddingLeft: '0',
  },
  metricsToggleButtonLast: {
    paddingRight: '0',
  },
  metricsToggleControlButton: {
    padding: '6px 0',
    marginTop: '-1px',
    '&:focus, &:active': {
      color: theme.palette.action.disabled,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: theme.palette.textContrast,
      backgroundColor: 'transparent',
    },
  },
  smallIcon: {
    fontSize: '1.2rem',
  },
  smallerIcon: {
    fontSize: '1rem',
  },
}));

type DateRangeBarProps = {
  defaultDateRange?: DateRanges;
};

function DateRangeBar({ defaultDateRange }: DateRangeBarProps) {
  const { state, setDateRange } = useChartDateRanges();
  const dateRange = state?.dateRange;
  const classes = useStyles();
  const [activeToggle, setActiveToggle]: [
    DateRanges,
    Dispatch<SetStateAction<DateRanges>>,
  ] = useState(dateRange);

  useEffect(function setInitialDateRangeOverride() {
    if (defaultDateRange) {
      setActiveToggle(defaultDateRange);
      setDateRange({ type: defaultDateRange });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateRange = (
    _event: SyntheticEvent,
    newDateRange: DateRanges,
  ) => {
    const activeToggleButton = [
      'previousRange',
      'nextRange',
      'all',
      'today',
    ].includes(newDateRange)
      ? activeToggle
      : newDateRange;

    setActiveToggle(activeToggleButton);
    setDateRange({ type: newDateRange });
  };

  const isActive = (value: string) => value === activeToggle;

  return (
    <ToggleButtonGroup
      exclusive
      value={dateRange}
      onChange={handleDateRange}
      aria-label="Date range"
      size="small"
      className={classes.metricsToggleButtonGroup}
      data-testid="date-range-bar"
    >
      <ToggleButton
        value="previousRange"
        className={`${classes.metricsToggleButton} ${classes.metricsToggleControlButton}`}
        disableRipple
        title="Previous"
        data-testid="date-range-bar-previous"
      >
        <NavigateBeforeIcon className={classes.smallIcon} />
      </ToggleButton>

      {dateRangeConfig.map(
        ({ name, value, title, text }, index, configArray) => {
          const classNames = [classes.metricsToggleButton];

          if (index === 0) {
            classNames.unshift(classes.metricsToggleButtonFirst);
          }
          if (index === configArray.length - 1) {
            classNames.unshift(classes.metricsToggleButtonLast);
          }

          return (
            <ToggleButton
              key={`date-range-bar-${name}`}
              value={value}
              className={classNames.join(' ')}
              disabled={isActive(value)}
              disableRipple
              title={title}
              data-testid={`date-range-bar-${name}`}
            >
              {text}
            </ToggleButton>
          );
        },
      )}

      <ToggleButton
        value="nextRange"
        className={`${classes.metricsToggleButton} ${classes.metricsToggleControlButton}`}
        disableRipple
        title="Next"
        data-testid="date-range-bar-next"
      >
        <NavigateNextIcon className={classes.smallIcon} />
      </ToggleButton>
      <ToggleButton
        value="today"
        className={`${classes.metricsToggleButton} ${classes.metricsToggleControlButton}`}
        disableRipple
        title="Today"
        data-testid="date-range-bar-today"
      >
        <TodayIcon className={classes.smallerIcon} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export { DateRangeBar };
