// eslint-disable-next-line
import React from 'react';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';

function CurrentRangeDisplay() {
  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const startDate = format(new Date(dateFrom), `MMM do y`);
  const endDate = format(new Date(dateTo), `MMM do y`);

  return (
    <Typography color="textSecondary" variant="body2">
      Data from {startDate} to {endDate}
    </Typography>
  );
}

export { CurrentRangeDisplay };
