import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import {
  MeanTimeToResolution,
  MeanTimeToResolutionResult,
} from '../../metrics';
import { ChartData } from 'chart.js';
import { parseISO } from 'date-fns';
import { buildWeekDataConfig } from '../../lib/chart/buildWeekDataConfig';
import { commonTargetDatasetOptions } from '../../lib/chart/commonTargetDatasetOptions';

const meanRgbColour = '173,209,75';
const meanRgbBorderColour = '152,184,66';

function transformMeanTimeToResolution({
  meanTimeToResolution,
}: MeanTimeToResolutionResult): ChartData {
  return buildWeekDataConfig<MeanTimeToResolution>(
    meanTimeToResolution,
    [buildAverageDataset],
    buildServiceLevelObjectivesTargetDataset,
  );
}

function buildAverageDataset(meanTimeToResolution: MeanTimeToResolution[]) {
  const data = meanTimeToResolution.map(
    ({ week_starting, average_minutes_to_resolve }: MeanTimeToResolution) => {
      return {
        x: parseISO(week_starting),
        y: average_minutes_to_resolve,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${meanRgbColour},0.3)`,
    borderColor: `rgba(${meanRgbBorderColour},1)`,
    pointBorderColor: `rgba(${meanRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${meanRgbColour},1)`,
    data,
  };
}

function buildServiceLevelObjectivesTargetDataset(
  meanTimeToResolution: MeanTimeToResolution[],
) {
  return {
    ...commonTargetDatasetOptions,
    label: 'SLO',
    data: meanTimeToResolution.map(
      ({ week_starting, service_level_objective }) => ({
        x: parseISO(week_starting),
        y: service_level_objective,
      }),
    ),
  };
}

export { transformMeanTimeToResolution };
