import { Chart, LegendElement, LegendItem } from 'chart.js';

interface BarChartWithLegend extends Chart {
  legend: LegendElement<'bar'>;
}

/**
 * Reset bar chart legends to use the original/highlighted colour
 */
const resetLegendsPlugin = {
  id: 'reset-legends',

  afterDraw: function afterDraw(chart: BarChartWithLegend) {
    const chartCtx: CanvasRenderingContext2D = chart?.ctx;
    const originalLegendColours = chart?.data?.datasets.map(
      (dataset: any) =>
        dataset?.backgroundColor?.[dataset?.backgroundColor?.length - 1],
    );

    if (chartCtx) {
      chartCtx.save();

      chart.legend.legendItems = chart?.legend.legendItems?.map(
        (legend: LegendItem, index: number) => ({
          ...legend,
          fillStyle: originalLegendColours[index],
        }),
      );

      chartCtx.restore();
    }
  },
};

export { resetLegendsPlugin };
