import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import { ServiceAvailability, ServiceAvailabilityResult } from '../../metrics';
import { ChartData } from 'chart.js';
import { commonTargetDatasetOptions } from '../../lib/chart/commonTargetDatasetOptions';
import { buildDateDataConfig } from '../../lib/chart/buildDateDataConfig';

const averageRgbColour = '47,170,212';
const averageRgbBorderColour = '41,150,185';

function transformServiceAvailability({
  serviceAvailability,
}: ServiceAvailabilityResult): ChartData {
  return buildDateDataConfig<ServiceAvailability>(
    serviceAvailability,
    [buildAverageDataset],
    buildServiceLevelObjectivesTargetDataset,
  );
}

function buildAverageDataset(serviceAvailability: ServiceAvailability[]) {
  const data = serviceAvailability.map(
    ({ start_time, year, service_availability }: ServiceAvailability) => {
      return {
        x: new Date(start_time),
        y: parseFloat(service_availability) * 100,
        year,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${averageRgbColour},0.3)`,
    borderColor: `rgba(${averageRgbBorderColour},1)`,
    pointBorderColor: `rgba(${averageRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${averageRgbColour},1)`,
    data,
  };
}

function buildServiceLevelObjectivesTargetDataset(
  serviceAvailability: ServiceAvailability[],
) {
  return {
    ...commonTargetDatasetOptions,
    label: 'SLO',
    data: serviceAvailability.map(
      ({ start_time, service_level_objective }: ServiceAvailability) => ({
        x: new Date(start_time),
        y: service_level_objective,
      }),
    ),
  };
}

export { transformServiceAvailability };
