// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { metricsApiRef } from '../../api';

import { MetricsChart } from '../MetricsChart';
import { deploymentsConfig } from './deploymentsConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { useApi } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as number of deployments per time period.
      </Typography>
      <Typography variant="body2" paragraph>
        High numbers/on-demand deployments during a day indicate it is easy to
        make changes to production and produce value on-demand for pret
        constantly.
      </Typography>
      <Typography variant="body2" paragraph>
        If there is demand for work/change in a service and there is a low
        deployment frequency, the frequency will indicate that work is being
        batched up and risk is increased around deployments or feature launches.
      </Typography>
      <Typography variant="body2" paragraph>
        For more detailed information on your service level objectives, visit
        your services{' '}
        <Link
          to={`https://console.cloud.google.com/monitoring/services/237652659/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          monitoring page{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};
const Deployments = () => {
  const api = useApi(metricsApiRef);
  const { entity } = useEntity();

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const entityName = entity?.metadata?.name;
  const serviceName =
    entity?.metadata?.annotations?.['gcp/service-application-name'] ?? '';

  const { data: chartData, error } = useSWR(
    ['metrics-deployment', entityName, dateFrom, dateTo],
    () =>
      api.getDeployments(serviceName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!serviceName) {
    return null;
  }

  return (
    <MetricsChart
      heading="Deployment frequency"
      descriptionLead="How often are we putting work into production?"
      descriptionBody={<Description serviceName={serviceName} />}
      chartData={chartData}
      error={error}
      configuration={deploymentsConfig}
      chartName={entityName}
    />
  );
};

export { Deployments };
