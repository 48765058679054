import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import { LeadTime, LeadTimeResult } from '../../metrics';
import { ChartData } from 'chart.js';
import { parseISO } from 'date-fns';
import { buildWeekDataConfig } from '../../lib/chart/buildWeekDataConfig';
import { commonTargetDatasetOptions } from '../../lib/chart/commonTargetDatasetOptions';

const meanRgbColour = '173,209,75';
const meanRgbBorderColour = '152,184,66';
const percentile25RgbColour = '47,170,212';
const percentile25RgbBorderColour = '41,150,185';
const percentile50RgbColour = '214,55,41';
const percentile50RgbBorderColour = '188,48,35';
const percentile75RgbColour = '247,211,82';
const percentile75RgbBorderColour = '222,190,73';

function transformLeadTime({ leadTime }: LeadTimeResult): ChartData {
  return buildWeekDataConfig<LeadTime>(
    leadTime,
    [
      buildMeanDataset,
      buildPercentile25Dataset,
      buildPercentile50Dataset,
      buildPercentile75Dataset,
    ],
    buildServiceLevelObjectivesTargetDataset,
  );
}

function buildMeanDataset(leadTime: LeadTime[]) {
  const data = leadTime.map(
    ({ week_starting, mean_lead_time_to_production_hrs }: LeadTime) => {
      return {
        x: parseISO(week_starting),
        y: mean_lead_time_to_production_hrs,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${meanRgbColour},0.3)`,
    borderColor: `rgba(${meanRgbBorderColour},1)`,
    pointBorderColor: `rgba(${meanRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${meanRgbColour},1)`,
    data,
  };
}

function buildPercentile25Dataset(leadTime: LeadTime[]) {
  const data = leadTime.map(({ week_starting, percentile_25 }) => {
    return {
      x: parseISO(week_starting),
      y: percentile_25,
    };
  });

  return {
    ...commonDatasetOptions,
    label: '25%',
    backgroundColor: `rgba(${percentile25RgbColour},0.3)`,
    borderColor: `rgba(${percentile25RgbBorderColour},1)`,
    pointBorderColor: `rgba(${percentile25RgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${percentile25RgbColour},1)`,
    data,
  };
}

function buildPercentile50Dataset(leadTime: LeadTime[]) {
  const data = leadTime.map(({ week_starting, percentile_50 }) => {
    return {
      x: parseISO(week_starting),
      y: percentile_50,
    };
  });

  return {
    ...commonDatasetOptions,
    label: '50%',
    backgroundColor: `rgba(${percentile50RgbColour},0.3)`,
    borderColor: `rgba(${percentile50RgbBorderColour},1)`,
    pointBorderColor: `rgba(${percentile50RgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${percentile50RgbColour},1)`,
    data,
  };
}

function buildPercentile75Dataset(leadTime: LeadTime[]) {
  const data = leadTime.map(({ week_starting, percentile_75 }) => {
    return {
      x: parseISO(week_starting),
      y: percentile_75,
    };
  });

  return {
    ...commonDatasetOptions,
    label: '75%',
    backgroundColor: `rgba(${percentile75RgbColour},0.3)`,
    borderColor: `rgba(${percentile75RgbBorderColour},1)`,
    pointBorderColor: `rgba(${percentile75RgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${percentile75RgbColour},1)`,
    data,
  };
}

function buildServiceLevelObjectivesTargetDataset(leadTime: LeadTime[]) {
  return {
    ...commonTargetDatasetOptions,
    label: 'SLO',
    data: leadTime.map(({ week_starting, service_level_objective }) => ({
      x: parseISO(week_starting),
      y: service_level_objective,
    })),
  };
}

export { transformLeadTime };
