// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { metricsApiRef } from '../../api';

import { MetricsChart } from '../MetricsChart';
import { meanTimeToResolutionConfig } from './meanTimeToResolutionConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { MeanTimeToResolutionResult } from '../../metrics';

import { useApi } from '@backstage/core-plugin-api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from '@backstage/core-components';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" paragraph>
        Mean time to restore (MTTR) is defined as the average time it takes for
        a service to recover from a product or system failure.
      </Typography>
      <Typography variant="body2" paragraph>
        MTTR encompass the time when the service was not working as expected/was
        unusable. MTTR does not encompass incident response process time or time
        taken for actions to resolve underlying service issues.
      </Typography>
      <Typography variant="body2" paragraph>
        Low MTTR reduces the cost of opportunity when a service is unavailable.
      </Typography>
      <Typography variant="body2" paragraph>
        For more detailed information on your service level objectives, visit
        your services{' '}
        <Link
          to={`https://console.cloud.google.com/monitoring/services/237652659/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          monitoring page{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};
const MeanTimeToResolution = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const entityName = entity?.metadata?.name;
  const serviceName =
    entity?.metadata?.annotations?.['pagerduty.com/service'] ?? '';

  const { data: chartData, error } = useSWR(
    ['metrics-mean-time-to-resolution', entityName, dateFrom, dateTo],
    () =>
      api.getMeanTimeToResolution(serviceName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!serviceName) {
    return null;
  }

  return (
    <MetricsChart<MeanTimeToResolutionResult>
      heading="Mean time to recovery"
      descriptionLead="How much time does it take for a service to return to working as expected?"
      descriptionBody={<Description serviceName={serviceName} />}
      chartData={chartData}
      error={error}
      configuration={meanTimeToResolutionConfig}
      chartName={entityName}
    />
  );
};

export { MeanTimeToResolution };
