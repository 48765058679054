/* eslint-disable */
import React from 'react';
import { BackstageTheme } from '@backstage/theme';
import { Grid, makeStyles } from '@material-ui/core';
import {
  Header,
  Page,
  HeaderLabel,
  Link,
  TabbedLayout,
} from '@backstage/core-components';
import { DependencyVulnerabilitiesSummary } from './DependencyVulnerabilitiesSummary';
import { DependencyWindowOfExposureSummary } from './DependencyWindowOfExposureSummary';
import { ContainerVulnerabilitiesSummary } from './ContainerVulnerabilitiesSummary';
import { ContainerWindowOfExposureSummary } from './ContainerWindowOfExposureSummary';
import {
  EntityKindPicker,
  EntityListProvider,
} from '@backstage/plugin-catalog-react';
import { DependencyVulnerabilitiesTable } from './DependencyVulnerabilitiesTable';
import { ContainerVulnerabilitiesTable } from './ContainerVulnerabilitiesTable';

const dependencyVulnerabilitiesContent = (
  <>
    <Grid container spacing={3} direction="row">
      <Grid item xs={12} md={6}>
        <DependencyVulnerabilitiesSummary />
      </Grid>
      <Grid item xs={12} md={6}>
        <DependencyWindowOfExposureSummary />
      </Grid>
    </Grid>
    <Grid container spacing={3} direction="row">
      <Grid item xs={12}>
        <EntityListProvider>
          <EntityKindPicker initialFilter="component" hidden />
          <DependencyVulnerabilitiesTable />
        </EntityListProvider>
      </Grid>
    </Grid>
  </>
);

const containerVulnerabilitiesContent = (
  <>
    <Grid container spacing={3} direction="row">
      <Grid item xs={12} md={6}>
        <ContainerVulnerabilitiesSummary />
      </Grid>
      <Grid item xs={12} md={6}>
        <ContainerWindowOfExposureSummary />
      </Grid>
    </Grid>
    <Grid container spacing={3} direction="row">
      <Grid item xs={12}>
        <EntityListProvider>
          <EntityKindPicker initialFilter="component" hidden />
          <ContainerVulnerabilitiesTable />
        </EntityListProvider>
      </Grid>
    </Grid>
  </>
);

const useStyles = makeStyles<BackstageTheme>(theme => ({
  link: {
    color: theme.palette.banner.text,
  },
}));

const VulnerabilitiesContent = () => {
  const classes = useStyles();
  return (
    <Page themeId="tool">
      <Header
        title="Vulnerabilities"
        subtitle="A summary of vulnerabilities across Pret service images and repository dependencies"
      >
        <HeaderLabel
          label="Owner"
          value={
            <Link
              className={classes.link}
              variant="inherit"
              to="/catalog/default/group/team-digital-platform"
            >
              team-digital-platform
            </Link>
          }
        />
        <HeaderLabel label="Lifecycle" value="Beta" />
      </Header>

      <TabbedLayout>
        <TabbedLayout.Route path="/" title="Dependencies">
          {dependencyVulnerabilitiesContent}
        </TabbedLayout.Route>
        <TabbedLayout.Route path="/containers" title="Containers">
          {containerVulnerabilitiesContent}
        </TabbedLayout.Route>
      </TabbedLayout>
    </Page>
  );
};

export { VulnerabilitiesContent };
