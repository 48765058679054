// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { last } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { dependencyVulnerabilitiesSummaryConfig } from './dependencyVulnerabilitiesSummaryConfig';
import { DependencyVulnerabilitiesAggregatedResult } from '../../../metrics';
import { useApi } from '@backstage/core-plugin-api';
import { formatISO, sub } from 'date-fns';
import { MetricsSummaryChart } from '../../MetricsSummaryChart';

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the total number of open vulnerabilities in service
        dependencies.
      </Typography>
      <Typography variant="body2" paragraph>
        Detailed information on open dependency vulnerabilities can be seen on
        the GitHub{' '}
        <Link
          to="https://github.com/orgs/pretamanger/insights/dependencies"
          target="_blank"
          rel="noopener"
        >
          dependency insights page
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
        . For information per service please see the table below.
      </Typography>
    </>
  );
};

const DependencyVulnerabilitiesSummary = () => {
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi(metricsApiRef);
  const today = formatISO(new Date(), { representation: 'date' });
  const dateFrom = formatISO(sub(new Date(), { weeks: 1 }), {
    representation: 'date',
  });
  const dateTo = today;

  const { data: chartData, error } = useSWR(
    ['metrics-dependency-vulnerabilities-aggregated', dateFrom, dateTo],
    () =>
      api.getDependencyVulnerabilitiesAggregated({
        dateFrom,
        dateTo,
      }),
  );

  useEffect(
    function provideLoadingStatus() {
      if (!chartData && !error) {
        setIsLoading(true);
      }

      if (chartData || error) {
        setIsLoading(false);
      }
    },
    [chartData, error],
  );

  useEffect(
    function setTotalVulnerabilities() {
      const hasVulnerabilities =
        chartData?.openVulnerabilitiesAggregated.length ?? 0;

      if (hasVulnerabilities) {
        const latestAggregatedVulnerabilities = last(
          chartData?.openVulnerabilitiesAggregated,
        );

        const vulnerabilityCount =
          latestAggregatedVulnerabilities?.vulnerability_count ?? 0;

        setTotal(vulnerabilityCount);
      }
    },
    [chartData],
  );

  const CurrentOpenVulnerabilitiesMessage = () => (
    <Typography variant="body2" color="textSecondary">
      {isLoading ? '' : `${total} open vulnerabilities`}
    </Typography>
  );

  return (
    <MetricsSummaryChart<DependencyVulnerabilitiesAggregatedResult>
      heading="Open vulnerabilities"
      descriptionLead="How many vulnerabilities are open in service dependencies?"
      descriptionBody={<Description />}
      chartData={chartData}
      error={error}
      configuration={dependencyVulnerabilitiesSummaryConfig}
      chartName="aggregated"
      currentValueMessage={<CurrentOpenVulnerabilitiesMessage />}
      dataTestId="open-dependency-vulnerabilities-summary-chart"
    />
  );
};

export { DependencyVulnerabilitiesSummary };
