// eslint-disable-next-line
import React, {
  createContext,
  ReactElement,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { add, format, sub } from 'date-fns';

import { DateRanges } from '../metrics';

interface RangeDuration {
  [key: string]: number;
}

type Action = {
  type: DateRanges;
};

type Dispatch = (action: Action) => void;

type State = {
  dateRange: DateRanges;
  dateFrom: string;
  dateTo: string;
  rangeDuration: RangeDuration;
};

const isoFormat = 'yyyy-MM-dd';

const today = () => format(Date.now(), isoFormat);

function calculatePreviousRange(state: State) {
  const dateFrom = format(
    sub(new Date(state.dateFrom), state.rangeDuration),
    isoFormat,
  );
  const dateTo = format(
    add(new Date(dateFrom), state.rangeDuration),
    isoFormat,
  );

  return {
    dateFrom,
    dateTo,
  };
}

function calculateNextRange(state: State) {
  const dateFrom = format(
    add(new Date(state.dateFrom), state.rangeDuration),
    isoFormat,
  );
  const dateTo = format(
    add(new Date(dateFrom), state.rangeDuration),
    isoFormat,
  );

  return {
    dateFrom,
    dateTo,
  };
}

/**
 * Calculate the date range to be shown
 *
 * The dateForm is calculated in two ways, this is for the user experience.
 *  1) If the end date (dateTo) is equal to today. We calculate the start date (dateFrom) by subtracting the rangeDuration
 *  e.g { weeks: 1} from the end date.
 *
 *  2) If the end date (dateTo) is not equal to today then we set the start date (dateFrom) to state.dateFrom
 *
 *  This gives the following user experiences:
 *  1) Pressing one of the date choices e.g 4W will show you the range, 4 weeks ago - today. This means
 *  that when you switch between ranges, 1M, 2M etc the end date (dateTo) stays the same rather
 *  than jumping around
 *
 *  2) Pressing one of the date choices e.g 4W will show you the range, current start date + 4 weeks. This means
 *  that when you have initially moved dates via the previous or next buttons, the start date (dateFrom) stays the same
 *  if you switch between ranges, rather than the start date jumping around
 *
 * @param state
 * @param rangeDuration
 */
function calculateRange(state: State, rangeDuration: RangeDuration) {
  const dateFrom =
    state.dateTo === today()
      ? format(sub(new Date(state.dateTo), rangeDuration), isoFormat)
      : state.dateFrom;
  const dateTo = format(add(new Date(dateFrom), rangeDuration), isoFormat);

  return {
    dateFrom,
    dateTo,
  };
}

function chartChoicesReducer(state: State, action: Action) {
  switch (action.type) {
    case 'previousRange':
      return {
        ...state,
        ...calculatePreviousRange(state),
        dateRange: state.dateRange,
      };
    case 'twoWeeksRange':
      return {
        ...state,
        ...calculateRange(state, { weeks: 2 }),
        dateRange: action.type,
        rangeDuration: { weeks: 2 },
      };
    case 'oneMonthRange':
      return {
        ...state,
        ...calculateRange(state, { months: 1 }),
        dateRange: action.type,
        rangeDuration: { months: 1 },
      };
    case 'twoMonthsRange':
      return {
        ...state,
        ...calculateRange(state, { months: 2 }),
        dateRange: action.type,
        rangeDuration: { months: 2 },
      };
    case 'threeMonthsRange':
      return {
        ...state,
        ...calculateRange(state, { months: 3 }),
        dateRange: action.type,
        rangeDuration: { months: 3 },
      };
    case 'sixMonthsRange':
      return {
        ...state,
        ...calculateRange(state, { months: 6 }),
        dateRange: action.type,
        rangeDuration: { months: 6 },
      };
    case 'oneYearRange':
      return {
        ...state,
        ...calculateRange(state, { years: 1 }),
        dateRange: action.type,
        rangeDuration: { years: 1 },
      };
    case 'nextRange':
      return {
        ...state,
        ...calculateNextRange(state),
        dateRange: state.dateRange,
      };
    case 'today':
      return {
        ...state,
        dateFrom: format(sub(new Date(), state.rangeDuration), isoFormat),
        dateTo: today(),
        dateRange: state.dateRange,
      };
    default:
      throw new Error(`Unhandled type: ${action.type}`);
  }
}

// To override the default date range which will load a chart with the specified date range, pass the prop
// defaultDateRange to the <DateRangeBar />
const getInitialState = (): State => ({
  dateFrom: format(sub(Date.now(), { months: 2 }), isoFormat),
  dateTo: today(),
  dateRange: 'twoMonthsRange',
  rangeDuration: { months: 2 },
});

const ChartDateRangesContext = createContext<{
  state: State;
  setDateRange: Dispatch;
}>({
  state: getInitialState(),
  setDateRange: state => state,
});

const useChartDateRanges = () => useContext(ChartDateRangesContext);

function ChartDateRangesProvider({ children }: { children: ReactElement }) {
  const contextInitialState = getInitialState();
  const [state, setDateRange]: [State, Dispatch] = useReducer(
    chartChoicesReducer,
    contextInitialState,
  );

  const value = useMemo(() => ({ state, setDateRange }), [state]);

  return (
    <ChartDateRangesContext.Provider value={value}>
      {children}
    </ChartDateRangesContext.Provider>
  );
}

export { useChartDateRanges, ChartDateRangesProvider };
