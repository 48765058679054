/**
 * Build config without padding
 * @param chartData
 * @param datasetTransformers
 */
function buildSummaryDateDataConfig<
  T extends { year: number; month: string; day: string },
>(chartData: T[], datasetTransformers: Function[]) {
  return {
    labels: chartData.map(({ day, month, year }) => [day, month, year]),
    datasets: datasetTransformers.map(transformer => transformer(chartData)),
  };
}

export { buildSummaryDateDataConfig };
