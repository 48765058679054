function singularise(pluralisedWord: string, count: number) {
  let result;

  if (count === 1) {
    if (pluralisedWord.endsWith('ies')) {
      result = pluralisedWord.replace(/ies$/, 'y');
    }
  } else {
    result = pluralisedWord;
  }

  return result;
}

export { singularise };
