import {
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import getPretTechRadarData from '../data/techRadar';

class DiscoClient implements TechRadarApi {
  async load(): Promise<TechRadarLoaderResponse> {
    return await getPretTechRadarData();
  }
}

export { DiscoClient };
