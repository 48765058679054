import {
  LeadTimeResult,
  MeanTimeToResolutionResult,
  MeanTimeToResolutionAggregatedResult,
  ServiceAvailabilityResult,
  ServiceLatencyResult,
  DependencyVulnerabilitiesResult,
  DependencyWindowOfExposureResult,
  DependencyVulnerabilitiesAggregatedResult,
  DependencyRepositoryListResult,
  ContainerVulnerabilitiesResult,
  ContainerWindowOfExposureResult,
  ContainerVulnerabilitiesAggregatedResult,
  DependencyWindowOfExposureAggregatedResult,
  ContainerWindowOfExposureAggregatedResult,
  ContainerVulnerabilitiesServiceListResult,
  DeploymentsResult,
  SummaryMetricsResponse,
} from '../metrics';
import { createApiRef } from '@backstage/core-plugin-api';

export const metricsApiRef = createApiRef<MetricsApi>({
  id: 'plugin.metrics.service',
});

export type MetricsApi = {
  getLeadTime(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<LeadTimeResult>;
  getDeployments(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<DeploymentsResult>;
  getMeanTimeToResolution(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<MeanTimeToResolutionResult>;
  getMeanTimeToResolutionAggregated(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<MeanTimeToResolutionAggregatedResult>;
  getDependencyVulnerabilities(
    repoName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<DependencyVulnerabilitiesResult>;
  getDependencyVulnerabilitiesAggregated(
    params?: { [key: string]: string } | undefined,
  ): Promise<DependencyVulnerabilitiesAggregatedResult>;
  getContainerVulnerabilities(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<ContainerVulnerabilitiesResult>;
  getContainerVulnerabilitiesAggregated(
    params?: { [key: string]: string } | undefined,
  ): Promise<ContainerVulnerabilitiesAggregatedResult>;
  getServiceAvailability(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<ServiceAvailabilityResult>;
  getServiceLatency(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<ServiceLatencyResult>;
  getDependencyWindowOfExposure(
    repoName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<DependencyWindowOfExposureResult>;
  getDependencyWindowOfExposureAggregated(
    params?: { [key: string]: string } | undefined,
  ): Promise<DependencyWindowOfExposureAggregatedResult>;
  getContainerWindowOfExposure(
    serviceName: string,
    params?: { [key: string]: string } | undefined,
  ): Promise<ContainerWindowOfExposureResult>;
  getContainerWindowOfExposureAggregated(
    params?: { [key: string]: string } | undefined,
  ): Promise<ContainerWindowOfExposureAggregatedResult>;
  getDependencyVulnerabilitiesRepositoryList(
    params?: { [key: string]: string } | undefined,
  ): Promise<DependencyRepositoryListResult>;
  getContainerVulnerabilitiesServiceList(
    params?: { [key: string]: string } | undefined,
  ): Promise<ContainerVulnerabilitiesServiceListResult>;
  getIdToken(): Promise<{ token?: string | undefined }>;
  getApiGatewayUrl(): string;
  getSummaryMetrics(
    params?: { [key: string]: string } | undefined,
  ): Promise<SummaryMetricsResponse>;
};
