import { format } from 'date-fns';
import { buildDateAxisTickLabels } from '../../lib/chart/buildDateAxisTickLabels';
import { transformServiceLatency } from './transformServiceLatency';
import { noDataMessagePlugin } from '../../lib/chart/noDataMessagePlugin';

import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { ServiceLatencyResult } from '../../metrics';
import { pluralise } from '../../lib/pluralise';

function serviceLatencyConfig(
  chartData: ServiceLatencyResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformServiceLatency(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'line',
    data,
    plugins: [noDataMessage],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildDateAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          title: {
            display: true,
            text: 'Latency',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            precision: 0,
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
            callback: value => `${value}%`,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            filter: legendItem => legendItem?.text !== 'SLO',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
          callbacks: {
            title: items => {
              const rawData: any = items?.[1]?.raw;
              const { x: startDate } = rawData;
              return format(startDate, `EEEE do 'of' LLLL yyyy`);
            },
            label: item => {
              const datasetLabel = item?.dataset?.label;
              const serviceLatency = item?.formattedValue;
              const rawData: any = item?.raw;
              const { serviceLevelObjectiveLatencyThreshold } = rawData;

              if (datasetLabel === 'SLO') {
                return ` Service latency target: ${parseFloat(
                  parseFloat(serviceLatency).toFixed(4),
                )}% of requests are equal to or under ${serviceLevelObjectiveLatencyThreshold} ${pluralise(
                  'second',
                  serviceLevelObjectiveLatencyThreshold,
                )}`;
              }

              return ` ${datasetLabel} service latency was ${parseFloat(
                parseFloat(serviceLatency).toFixed(4),
              )}%`;
            },
            labelColor: (context): any => ({
              borderColor: context?.dataset?.borderColor,
              backgroundColor: context?.dataset?.backgroundColor,
              borderWidth: 2,
            }),
          },
        },
      },
    },
  };
}

export { serviceLatencyConfig };
