// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import { metricsApiRef } from '../../api';
import { MetricsChart } from '../MetricsChart';
import { incidentConfig } from './incidentConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { MeanTimeToResolutionAggregatedResult } from '../../metrics';
import { useApi } from '@backstage/core-plugin-api';

const Description = () => {
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined and measured as alerts fired in production for the service. This
        number won't match the number of times the incident response process was
        started for the service as multiple alerts can roll up into one incident
        response process.
      </Typography>
      <Typography variant="body2" paragraph>
        High numbers can indicate a service could need greater investment in
        resiliency, fault tolerance, and graceful degradation, or it can
        indicate noisy alerts.
      </Typography>
      <Typography variant="body2" paragraph>
        Low numbers indicate a service performs a lot of graceful degradation
        and fault tolerance or it can indicate an absence of alerts configured.
      </Typography>
    </>
  );
};

const Incident = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const entityName = entity?.metadata?.name;
  const serviceName =
    entity?.metadata?.annotations?.['pagerduty.com/service'] ?? '';

  const { data: chartData, error } = useSWR(
    [
      'metrics-mean-time-to-resolution-aggregated',
      entityName,
      dateFrom,
      dateTo,
    ],
    () =>
      api.getMeanTimeToResolutionAggregated(serviceName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!serviceName) {
    return null;
  }

  return (
    <MetricsChart<MeanTimeToResolutionAggregatedResult>
      heading="Incidents in production"
      descriptionLead="How often do alerts fire in production?"
      descriptionBody={<Description />}
      chartData={chartData}
      error={error}
      configuration={incidentConfig}
      chartName={entityName}
    />
  );
};

export { Incident };
