// eslint-disable-next-line
import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  infoBar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    paddingBottom: `${theme.spacing(1)}px`,
    minHeight: `${theme.spacing(6)}px`,
  },
  primary: {
    flex: '2 1 auto',
  },
  secondary: {
    flex: '3 3 auto',
    textAlign: 'right',
  },
}));

function InfoBar({
  primary,
  secondary,
}: {
  primary?: ReactNode;
  secondary?: ReactNode;
}) {
  const classes = useStyles();

  return (
    <header className={classes.infoBar}>
      {primary && <div className={classes.primary}>{primary}</div>}
      {secondary && <div className={classes.secondary}>{secondary}</div>}
    </header>
  );
}

export { InfoBar };
