import { PageTheme } from '@backstage/theme';

/*
  # How to add a shape
  1. Get the svg shape from figma, should be ~1400 wide, ~400 high
    and only the white->transparent mask, no colors.
  2. Run it through https://jakearchibald.github.io/svgomg/
  3. Run that through https://github.com/tigt/mini-svg-data-uri
    with something like https://npm.runkit.com/mini-svg-data-uri
  4. Wrap the output in `url("")`
  5. Give it a name and paste it into the `shapes` object below.
*/
export const shapes: Record<string, string> = {
  star: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 400'%3e%3cdefs%3e%3clinearGradient x1='23.062%25' y1='17.691%25' x2='94.766%25' y2='94.888%25' id='a'%3e%3cstop stop-color='white' stop-opacity='.3' offset='0%25'/%3e%3cstop stop-color='white' stop-opacity='0' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cpath fill='url(%23a)' fill-rule='nonzero' d='M1200.6 15l-53.563 155.61H1000l118.85 81.783-45.25 140.564 127-81.634L1328.638 394 1283.1 252.393 1400 170.61h-145.975z'/%3e%3c/svg%3e")`,
  stars: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1400 400'%3e%3cdefs%3e%3clinearGradient x1='23.062%25' y1='17.59%25' x2='94.766%25' y2='95.028%25' id='a'%3e%3cstop stop-color='white' stop-opacity='.3' offset='0%25'/%3e%3cstop stop-color='white' stop-opacity='0' offset='100%25'/%3e%3c/linearGradient%3e%3clinearGradient x1='23.062%25' y1='14.011%25' x2='94.766%25' y2='100%25' id='b'%3e%3cstop stop-color='white' stop-opacity='.3' offset='0%25'/%3e%3cstop stop-color='white' stop-opacity='0' offset='100%25'/%3e%3c/linearGradient%3e%3clinearGradient x1='11.624%25' y1='27.494%25' x2='110.123%25' y2='78.586%25' id='c'%3e%3cstop stop-color='white' stop-opacity='.3' offset='0%25'/%3e%3cstop stop-color='white' stop-opacity='0' offset='100%25'/%3e%3c/linearGradient%3e%3clinearGradient x1='23.062%25' y1='15.666%25' x2='94.766%25' y2='97.701%25' id='d'%3e%3cstop stop-color='white' stop-opacity='.3' offset='0%25'/%3e%3cstop stop-color='white' stop-opacity='0' offset='100%25'/%3e%3c/linearGradient%3e%3c/defs%3e%3cg fill='none' fill-rule='nonzero'%3e%3cpath fill='url(%23a)' transform='rotate(7 533.234 193.874)' d='M533.528 100.874l-26.246 76.367h-72.048l58.237 40.137-22.173 68.984 62.23-40.064 62.738 40.576-22.313-69.496 57.281-40.137h-71.528z'/%3e%3cpath fill='url(%23b)' transform='rotate(25 1155.578 211.877)' d='M1149.139 158.377l-7.728 44.108-39.333 6.253 35.26 16.93-6.142 39.71 30.51-27.347 37.757 16.78-18.187-36.13 27.802-26.955-39.048 6.208z'/%3e%3cpath fill='url(%23c)' transform='rotate(25 1035.958 197.474)' d='M1001.064 133.474l11.2 52.915-41.306 24.91 47.181 2.907 10.997 47.268 21.908-44.515 49.914 1.602-36.677-32.181 19.045-42.846-41.007 24.73z'/%3e%3cpath fill='url(%23d)' transform='rotate(25 1238.177 263.082)' d='M1241.82 221.082l-15.03 32.1-31.613-3.31 23.706 20.18-12.903 29.066 29.148-14.613 25.661 20.577-6.593-31.333 26.98-14.873-31.384-3.286z'/%3e%3c/g%3e%3c/svg%3e")`,
};

export const colorVariants: Record<string, string[]> = {
  pretRed: ['#9f1b32', '#711323'],
};

// As the background shapes and colors are decorative, we place them onto
// the page as a css background-image instead of an html element of its own.
// Utility to not have to write colors and shapes twice.
export function genPageTheme(colors: string[], shape: string): PageTheme {
  const gradientColors = colors.length === 1 ? [colors[0], colors[0]] : colors;
  const gradient = `linear-gradient(90deg, ${gradientColors.join(', ')})`;
  const backgroundImage = `${shape},  ${gradient}`;
  const fontColor = '#000';

  return { colors, shape, backgroundImage, fontColor };
}

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme(colorVariants.pretRed, shapes.stars),
  documentation: genPageTheme(colorVariants.pretRed, shapes.stars),
  tool: genPageTheme(colorVariants.pretRed, shapes.star),
  service: genPageTheme(colorVariants.pretRed, shapes.star),
  website: genPageTheme(colorVariants.pretRed, shapes.stars),
  library: genPageTheme(colorVariants.pretRed, shapes.star),
  other: genPageTheme(colorVariants.pretRed, shapes.stars),
  app: genPageTheme(colorVariants.pretRed, shapes.star),
};
