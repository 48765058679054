import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import { ServiceLatency, ServiceLatencyResult } from '../../metrics';
import { ChartData } from 'chart.js';
import { commonTargetDatasetOptions } from '../../lib/chart/commonTargetDatasetOptions';
import { buildDateDataConfig } from '../../lib/chart/buildDateDataConfig';

const averageRgbColour = '191,41,214';
const averageRgbBorderColour = '168,35,188';

function transformServiceLatency({
  serviceLatency,
}: ServiceLatencyResult): ChartData {
  return buildDateDataConfig<ServiceLatency>(
    serviceLatency,
    [buildAverageDataset],
    buildServiceLevelObjectivesTargetDataset,
  );
}

function buildAverageDataset(serviceLatency: ServiceLatency[]) {
  const data = serviceLatency.map(
    ({ start_time, service_latency }: ServiceLatency) => {
      return {
        x: new Date(start_time),
        y: parseFloat(service_latency) * 100,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${averageRgbColour},0.3)`,
    borderColor: `rgba(${averageRgbBorderColour},1)`,
    pointBorderColor: `rgba(${averageRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${averageRgbColour},1)`,
    data,
  };
}

function buildServiceLevelObjectivesTargetDataset(
  serviceLatency: ServiceLatency[],
) {
  return {
    ...commonTargetDatasetOptions,
    label: 'SLO',
    data: serviceLatency.map(
      ({ start_time, service_level_objective, slo_latency_threshold }) => ({
        x: new Date(start_time),
        y: service_level_objective,
        serviceLevelObjectiveLatencyThreshold: slo_latency_threshold,
      }),
    ),
  };
}

export { transformServiceLatency };
