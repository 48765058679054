import { oktaAuthApiRef } from '@backstage/core-plugin-api';

export const providers = [
  {
    id: 'okta-auth-provider',
    title: 'Okta',
    message: 'Sign In using Okta',
    apiRef: oktaAuthApiRef,
  },
];
