function entityToGitHubRepositoryMap(entityName: string) {
  switch (true) {
    case entityName === 'disco-ui':
      return 'disco';
    case entityName === 'disco-catalog-api':
      return 'disco';
    default:
      return entityName;
  }
}

export { entityToGitHubRepositoryMap };
