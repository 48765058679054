import { format } from 'date-fns';
import { pluralise } from '../../lib/pluralise';
import { transformDependencyWindowOfExposure } from './transformDependencyWindowOfExposure';
import { noDataMessagePlugin } from '../../lib/chart/noDataMessagePlugin';
import { buildDateAxisTickLabels } from '../../lib/chart/buildDateAxisTickLabels';

import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { DependencyWindowOfExposureResult } from '../../metrics';

function dependencyWindowOfExposureConfig(
  chartData: DependencyWindowOfExposureResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformDependencyWindowOfExposure(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'line',
    data,
    plugins: [noDataMessage],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildDateAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          display: true,
          min: 0,
          title: {
            display: true,
            text: 'Days',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          callbacks: {
            title: items => {
              const rawData: any = items?.[0]?.raw;
              const { x: metricDate } = rawData;
              return format(metricDate, `EEEE do 'of' LLLL yyyy`);
            },
            label: item => {
              const datasetLabel = item?.dataset?.label;
              const windowOfExposureDays = item?.formattedValue;

              if (windowOfExposureDays === '0') {
                return ' No window of exposure';
              }

              if (datasetLabel?.toLowerCase() !== 'average') {
                return ` For ${datasetLabel} of vulnerabilities, the window of exposure is under or equal to ${windowOfExposureDays} ${pluralise(
                  'day',
                  windowOfExposureDays,
                )}`;
              }

              return ` ${datasetLabel} window of exposure is ${windowOfExposureDays} ${pluralise(
                'day',
                windowOfExposureDays,
              )}`;
            },
            labelColor: (context): any => ({
              borderColor: context?.dataset?.borderColor,
              backgroundColor: context?.dataset?.backgroundColor,
              borderWidth: 2,
            }),
          },
        },
      },
    },
  };
}

export { dependencyWindowOfExposureConfig };
