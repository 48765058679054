// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { last } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { dependencyWindowOfExposureSummaryConfig } from './dependencyWindowOfExposureSummaryConfig';
import { DependencyWindowOfExposureAggregatedResult } from '../../../metrics';
import { useApi } from '@backstage/core-plugin-api';
import { formatISO, sub } from 'date-fns';
import { MetricsSummaryChart } from '../../MetricsSummaryChart';
import { pluralise } from '../../../lib/pluralise';
import { calculateWindowOfExposure } from '../../../lib/chart/calculateWindowOfExposure';

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the number of days since the vulnerability was discovered in
        a dependency in a services GitHub repository, or the number of days
        since a vulnerable dependency was first used. The number will rise every
        day for every vulnerability that has not been resolved.
      </Typography>
      <Typography variant="body2" paragraph>
        Detailed information on open dependency vulnerabilities can be seen on
        the GitHub{' '}
        <Link
          to="https://github.com/orgs/pretamanger/insights/dependencies"
          target="_blank"
          rel="noopener"
        >
          dependency insights page
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
        . For information per service please see the table below.
      </Typography>
    </>
  );
};

const DependencyWindowOfExposureSummary = () => {
  const [windowOfExposure, setWindowOfExposure] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi(metricsApiRef);
  const today = formatISO(new Date(), { representation: 'date' });
  const dateFrom = formatISO(sub(new Date(), { weeks: 1 }), {
    representation: 'date',
  });
  const dateTo = today;

  const { data: chartData, error } = useSWR(
    ['metrics-dependency-window-of-exposure-aggregated', dateFrom, dateTo],
    () =>
      api.getDependencyWindowOfExposureAggregated({
        dateFrom,
        dateTo,
      }),
  );

  useEffect(
    function provideLoadingStatus() {
      if (!chartData && !error) {
        setIsLoading(true);
      }

      if (chartData || error) {
        setIsLoading(false);
      }
    },
    [chartData, error],
  );

  useEffect(
    function setCurrentWindowOfExposure() {
      const hasWindowOfExposure =
        chartData?.windowOfExposureAggregated.length ?? 0;

      if (hasWindowOfExposure) {
        const latestAggregatedWindowOfExposure = last(
          chartData?.windowOfExposureAggregated,
        );

        setWindowOfExposure(
          latestAggregatedWindowOfExposure?.mean_milliseconds ?? 0,
        );
      }
    },
    [chartData],
  );

  const windowOfExposureDays = calculateWindowOfExposure(windowOfExposure);

  const CurrentWindowOfExposureMessage = () => (
    <Typography variant="body2" color="textSecondary">
      {isLoading
        ? ''
        : `Average window of exposure is ${windowOfExposureDays} ${pluralise(
            'day',
            windowOfExposureDays.toString(),
          )}`}
    </Typography>
  );

  return (
    <MetricsSummaryChart<DependencyWindowOfExposureAggregatedResult>
      heading="Window of exposure"
      descriptionLead="How long are services exposed due to vulnerable dependencies?"
      descriptionBody={<Description />}
      chartData={chartData}
      error={error}
      configuration={dependencyWindowOfExposureSummaryConfig}
      chartName="aggregated"
      currentValueMessage={<CurrentWindowOfExposureMessage />}
      dataTestId="dependency-window-of-exposure-summary-chart"
    />
  );
};

export { DependencyWindowOfExposureSummary };
