const greyRgbColour = '220,220,220';

export const commonDatasetOptions: any = {
  fill: true,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: `rgba(${greyRgbColour},1)`,
  pointBorderWidth: 1,
  pointHoverRadius: 8,
  pointHoverBorderColor: `rgba(${greyRgbColour},1)`,
  pointHoverBorderWidth: 4,
  pointRadius: 4,
  pointHitRadius: 10,
};
