import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 90,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110 203"
    >
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <g transform="translate(5)">
          <path
            fill="#9F1B32"
            fillRule="nonzero"
            d="M33.772 159.755h16.5v17.649h-16.5z"
          />
          <ellipse
            stroke="#000"
            strokeWidth="5"
            cx="29.5"
            cy="167.348"
            rx="29.5"
            ry="29.757"
          />
          <path
            stroke="#000"
            strokeWidth="5"
            d="M16.276 140.874v52.597M33.976 137.796v59.925M50.25 145.657v41.976M0 159.344h59M0 177.814h59M25.228 135.538c0-4.098 1.377-6.148 4.132-6.148 2.942-.16 4.412 1.89 4.412 6.148h-8.544zM29.5.5v129.087"
          />
        </g>
        <circle
          stroke="#000"
          strokeWidth="2"
          fill="#9F1B32"
          fillRule="nonzero"
          cx="74.5"
          cy="182.5"
          r="3.5"
        />
        <circle fill="#9F1B32" fillRule="nonzero" cx="9.5" cy="129.5" r="2.5" />
        <g stroke="#000" strokeWidth="2">
          <path d="M63.5 117.385v9.23M68.5 122h-10" />
        </g>
        <g stroke="#000" strokeWidth="2">
          <path d="M5.5 192.385v9.23M10.5 197H.5" />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
