import React, { useContext, PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ErrorIcon from '@material-ui/icons/Error';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { Settings as SidebarSettings } from '@backstage/plugin-user-settings';
import { SidebarSearch } from '@backstage/plugin-search';
import {
  Sidebar,
  SidebarPage,
  sidebarConfig,
  SidebarContext,
  SidebarItem,
  SidebarDivider,
  SidebarSpace,
  Link,
} from '@backstage/core-components';
import BuildIcon from '@material-ui/icons/Build';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: -1,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarSearch />
      <SidebarDivider />
      {/* Global nav, not org-specific */}
      <SidebarItem icon={MenuBookIcon} to="catalog" text="Catalog" />
      <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
      <SidebarItem
        icon={ErrorIcon}
        to="vulnerabilities"
        text="Vulnerabilities"
      />
      <SidebarItem icon={EqualizerIcon} to="metrics" text="Metrics" />
      <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
      {/* End global nav */}
      <SidebarDivider />
      <SidebarSpace />
      <SidebarDivider />
      <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
      <SidebarSettings />
    </Sidebar>
    {children}
  </SidebarPage>
);

export default Root;
