import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import {
  DependencyWindowOfExposure,
  DependencyWindowOfExposureResult,
} from '../../metrics';
import { ChartData } from 'chart.js';
import { provideDateLabelParts } from '../../lib/chart/provideDateLabelParts';
import { calculateWindowOfExposure } from '../../lib/chart/calculateWindowOfExposure';

const meanRgbColour = '173,209,75';
const meanRgbBorderColour = '152,184,66';
const percentile95RgbColour = '47,170,212';
const percentile95RgbBorderColour = '41,150,185';

function transformDependencyWindowOfExposure({
  windowOfExposure,
}: DependencyWindowOfExposureResult): ChartData {
  const meanWindowOfExposureDataset = transformMean(windowOfExposure);
  const percentile95Dataset = transformPercentile95(windowOfExposure);
  const labels =
    provideDateLabelParts<DependencyWindowOfExposure>(windowOfExposure);

  return {
    labels,
    datasets: [meanWindowOfExposureDataset, percentile95Dataset],
  };
}

function transformMean(windowOfExposure: DependencyWindowOfExposure[]) {
  const data = windowOfExposure.map(
    ({ iso_date, mean_milliseconds }: DependencyWindowOfExposure) => {
      return {
        x: new Date(iso_date),
        y: calculateWindowOfExposure(mean_milliseconds),
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${meanRgbColour},0.3)`,
    borderColor: `rgba(${meanRgbBorderColour},1)`,
    pointBorderColor: `rgba(${meanRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${meanRgbColour},1)`,
    data,
  };
}

function transformPercentile95(windowOfExposure: DependencyWindowOfExposure[]) {
  const data = windowOfExposure.map(
    ({ iso_date, percentile_95_milliseconds }) => {
      return {
        x: new Date(iso_date),
        y: calculateWindowOfExposure(percentile_95_milliseconds),
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: '95%',
    backgroundColor: `rgba(${percentile95RgbColour},0.3)`,
    borderColor: `rgba(${percentile95RgbBorderColour},1)`,
    pointBorderColor: `rgba(${percentile95RgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${percentile95RgbColour},1)`,
    data,
  };
}

export { transformDependencyWindowOfExposure };
