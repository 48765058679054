import { provideWeekLabelParts } from './provideWeekLabelParts';

function buildWeekDataConfig<T extends { year: number; week_number: number }>(
  chartData: T[],
  datasetTransformers: Function[],
  buildTargetIndicatorDataset?: Function,
) {
  const datasets = datasetTransformers.map(transformer =>
    transformer(chartData),
  );

  if (buildTargetIndicatorDataset) {
    const targetDataset = buildTargetIndicatorDataset(chartData);
    datasets.unshift(targetDataset);
  }

  return {
    labels: provideWeekLabelParts<T>(chartData),
    datasets,
  };
}

export { buildWeekDataConfig };
