import { commonDatasetOptions } from '../../lib/chart/commonDatasetOptions';
import {
  MeanTimeToResolutionAggregated,
  MeanTimeToResolutionAggregatedResult,
} from '../../metrics';
import { ChartData } from 'chart.js';
import { parseISO } from 'date-fns';
import { buildWeekDataConfig } from '../../lib/chart/buildWeekDataConfig';

const incidentRgbColour = '247,211,82';
const incidentRgbBorderColour = '222,190,73';

function transformIncident({
  meanTimeToResolutionAggregated,
}: MeanTimeToResolutionAggregatedResult): ChartData {
  return buildWeekDataConfig<MeanTimeToResolutionAggregated>(
    meanTimeToResolutionAggregated,
    [buildIncidentsDataset],
  );
}

function buildIncidentsDataset(
  meanTimeToResolution: MeanTimeToResolutionAggregated[],
) {
  const data = meanTimeToResolution.map(
    ({
      week_starting,
      total_incident_count,
    }: MeanTimeToResolutionAggregated) => {
      return {
        x: parseISO(week_starting),
        y: total_incident_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Incidents',
    backgroundColor: `rgba(${incidentRgbColour},0.3)`,
    borderColor: `rgba(${incidentRgbBorderColour},1)`,
    pointBorderColor: `rgba(${incidentRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${incidentRgbColour},1)`,
    data,
  };
}

export { transformIncident };
