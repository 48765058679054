import { transformDependencyVulnerabilitiesSummary } from './transformDependencyVulnerabilitiesSummary';
import { noDataMessagePlugin } from '../../../lib/chart/noDataMessagePlugin';
import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { DependencyVulnerabilitiesAggregatedResult } from '../../../metrics';
import { buildDateAxisTickLabels } from '../../../lib/chart/buildDateAxisTickLabels';
import { format, isToday } from 'date-fns';
import { singularise } from '../../../lib/singularise';
import { resetLegendsPlugin } from '../../../lib/chart/resetLegendsPlugin';

function dependencyVulnerabilitiesSummaryConfig(
  chartData: DependencyVulnerabilitiesAggregatedResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformDependencyVulnerabilitiesSummary(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'bar',
    data,
    plugins: [noDataMessage, resetLegendsPlugin],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          display: true,
          stacked: true,
          beginAtZero: true,
          ticks: {
            color: palette.textSubtle,
            autoSkip: false,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildDateAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          display: true,
          stacked: true,
          min: 0,
          title: {
            display: true,
            text: 'Count',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            color: palette.textSubtle,
            autoSkip: false,
            maxRotation: 0,
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          reverse: false,
          position: 'bottom',
          labels: {
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          callbacks: {
            title: items => {
              const totalVulnerabilityCount = items.reduce(
                (total, { formattedValue }) =>
                  total + parseInt(formattedValue, 10),
                0,
              );
              const firstItemRawData: any = items?.[0]?.raw;
              const { x: startDate } = firstItemRawData;

              if (isToday(startDate)) {
                return `Currently you have ${totalVulnerabilityCount} open ${singularise(
                  'vulnerabilities',
                  totalVulnerabilityCount,
                )}`;
              }

              return `On ${format(
                startDate,
                `EEEE do 'of' LLLL yyyy`,
              )} you had ${totalVulnerabilityCount} open ${singularise(
                'vulnerabilities',
                totalVulnerabilityCount,
              )}`;
            },
            label: item => {
              const datasetLabel = item?.dataset?.label?.toLowerCase();
              const vulnerabilityCount = item?.formattedValue;

              return ` ${vulnerabilityCount} ${datasetLabel}`;
            },
            labelColor: (context: any) => {
              const lastBackGroundColour =
                context?.dataset?.backgroundColor[
                  context?.dataset?.backgroundColor?.length - 1
                ];

              return {
                borderColor: context?.dataset?.borderColor,
                backgroundColor: lastBackGroundColour,
                borderWidth: 2,
              };
            },
          },
        },
      },
    },
  };
}

export { dependencyVulnerabilitiesSummaryConfig };
