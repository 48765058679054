// https://github.com/zalando/tech-radar

import {
  RadarRing,
  RadarQuadrant,
  RadarEntry,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'hold', name: 'HOLD', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

const entries = new Array<RadarEntry>();

// Languages
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Programming Language',
    },
  ],
  url: '#',
  key: 'javascript',
  id: 'javascript',
  title: 'JavaScript',
  quadrant: 'languages',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Programming Language',
    },
  ],
  url: 'https://www.typescriptlang.org/',
  key: 'typescript',
  id: 'typescript',
  title: 'TypeScript',
  quadrant: 'languages',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Programming Language',
    },
  ],
  url: 'https://kotlinlang.org/',
  key: 'kotlin',
  id: 'kotlin',
  title: 'Kotlin',
  quadrant: 'languages',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Programming Language',
    },
  ],
  url: 'https://www.terraform.io/',
  key: 'terraform',
  id: 'terraform',
  title: 'Terraform',
  quadrant: 'languages',
});

// Frameworks
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'API Framework',
    },
  ],
  url: 'https://www.http4k.org/',
  key: 'http4k',
  id: 'http4k',
  title: 'Http4k',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'API Framework',
    },
  ],
  url: 'https://expressjs.com/',
  key: 'express',
  id: 'express',
  title: 'Express',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Tooling',
    },
  ],
  url: 'https://webpack.js.org/',
  key: 'webpack',
  id: 'webpack',
  title: 'Webpack',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'UI Framework',
    },
  ],
  url: 'https://reactjs.org/',
  key: 'react',
  id: 'react',
  title: 'React',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'UI Framework, API Framework',
    },
  ],
  url: 'https://nextjs.org/',
  key: 'nextjs',
  id: 'nextjs',
  title: 'Next.js',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Unit Testing Frameworks',
    },
  ],
  url: 'https://junit.org/junit5/',
  key: 'junit',
  id: 'junit',
  title: 'Junit',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Unit Testing Frameworks',
    },
  ],
  url: 'https://jestjs.io/',
  key: 'jest',
  id: 'jest',
  title: 'Jest',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'User Journey Testing',
    },
  ],
  url: 'https://www.cypress.io/',
  key: 'cypress',
  id: 'cypress',
  title: 'Cypress',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Accessibility and Browser testing',
    },
  ],
  url: 'https://www.browserstack.com/',
  key: 'browserstack',
  id: 'browserstack',
  title: 'Browserstack',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Performance Load Testing',
    },
  ],
  url: 'https://gatling.io/',
  key: 'gatling',
  id: 'gatling',
  title: 'Gatling',
  quadrant: 'frameworks',
});

// Process
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description:
        'Service Team Product Owners are accountable for the decisions, but service teams are held responsible for their services',
    },
  ],
  url: '#',
  key: 'service-ownership',
  id: 'service-ownership',
  title: 'Service ownership',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Work is peer-reviewed by Service Team members',
    },
  ],
  url: '#',
  key: 'peer-review',
  id: 'peer-review',
  title: 'Peer Review',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2021-02-26'),
      description:
        'Work is produced by a Pair of team members, acting in either a driver or navigator role',
    },
  ],
  url: '#',
  key: 'pairing',
  id: 'pairing',
  title: 'Pairing/Mobbing',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description:
        'Deployments are separate from releases of work to users. Teams are in control of when they release work to users. Teams are expected to build quality in and make decisions based on the operational running of their service.',
    },
  ],
  url: '#',
  key: 'change-control',
  id: 'change-control',
  title: 'Change control',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description:
        'No blame culture, focused on learning to improve future response, routine chaos sessions ran to prepare & investigate potential failures, post-incident reviews required',
    },
  ],
  url: '#',
  key: 'incident-response',
  id: 'incident-response',
  title: 'Incident Response',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Incident Response Tooling - Templated runbooks',
    },
  ],
  url: 'https://github.com/pretamanger/runbooks',
  key: 'templated-runbooks',
  id: 'templated-runbooks',
  title: 'Templated runbooks',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2021-02-26'),
      description: 'Contract Testing',
    },
  ],
  url: 'https://docs.pact.io/',
  key: 'contract-testing',
  id: 'contract-testing',
  title: 'Contract Testing',
  quadrant: 'process',
});

// Infrastructure
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Build & Deploy server',
    },
  ],
  url: 'https://github.com/features/actions',
  key: 'github-actions',
  id: 'github-actions',
  title: 'GitHub Actions',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Cloud',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'google-cloud-platform',
  id: 'google-cloud-platform',
  title: 'Google Cloud Platform',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Google Cloud Platform - IAM Service Accounts',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'gcp-iam-service-accounts',
  id: 'gcp-iam-service-accounts',
  title: 'GCP - IAM Service Accounts',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Google Cloud Platform - Secret Manager',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'gcp-secret-manager',
  id: 'gcp-secret-manager',
  title: 'GCP - Secret Manager',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Service Runtime hosting & scaling',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'gcp-cloud-run',
  id: 'gcp-cloud-run',
  title: 'GCP - Cloud Run',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Runtime artefact',
    },
  ],
  url: 'https://www.docker.com/',
  key: 'docker',
  id: 'docker',
  title: 'Docker',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description:
        'Observability Tooling - Google Cloud Platform - Logging/Tracing/Profiling/Custom Metrics, Templated GCP Monitoring Dashboards',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'observability-tooling-gcp',
  id: 'observability-tooling-gcp',
  title: 'GCP - Observability Tooling',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Incident Response Tooling - PagerDuty',
    },
  ],
  url: 'https://pret-a-manger.pagerduty.com/',
  key: 'pagerduty',
  id: 'pagerduty',
  title: 'PagerDuty',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Incident Response Tooling - Templated GCP Alerts',
    },
  ],
  url: 'https://cloud.google.com/',
  key: 'templated-gcp-alerts',
  id: 'templated-gcp-alerts',
  title: 'Templated GCP Alerts',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-02-26'),
      description: 'Incident Response Tooling - Microsoft Teams',
    },
  ],
  url: '#',
  key: 'microsoft-teams',
  id: 'microsoft-teams',
  title: 'Microsoft Teams',
  quadrant: 'infrastructure',
});

export default function getPretTechRadarData(): Promise<TechRadarLoaderResponse> {
  return Promise.resolve({
    rings,
    quadrants,
    entries,
  });
}
