import { DateRangeConfig } from '../../metrics';

const dateRangeConfig: DateRangeConfig[] = [
  {
    name: 'two-weeks',
    value: 'twoWeeksRange',
    title: '2 weeks',
    text: '2W',
  },
  {
    name: 'one-month',
    value: 'oneMonthRange',
    title: '1 month',
    text: '1M',
  },
  {
    name: 'two-months',
    value: 'twoMonthsRange',
    title: '2 months',
    text: '2M',
  },
  {
    name: 'three-months',
    value: 'threeMonthsRange',
    title: '3 months',
    text: '3M',
  },
  {
    name: 'six-months',
    value: 'sixMonthsRange',
    title: '6 months',
    text: '6M',
  },
  {
    name: 'one-year',
    value: 'oneYearRange',
    title: '1 year',
    text: '1Y',
  },
];

export { dateRangeConfig };
