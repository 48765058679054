// eslint-disable-next-line
import React from 'react';
import { Typography } from '@material-ui/core';
import { format, sub } from 'date-fns';

function CurrentDateDisplay() {
  const today = format(new Date(), 'MMM do y');
  const oneWeekAgo = format(sub(new Date(), { weeks: 1 }), 'MMM do y');

  return (
    <Typography color="textSecondary" variant="body2">
      Data from {oneWeekAgo} to {today}
    </Typography>
  );
}

export { CurrentDateDisplay };
