import { metricsRootRouteRef, vulnerabilitiesRootRouteRef } from './routes';
import { metricsApiRef, MetricsClient } from './api';
import {
  createApiFactory,
  createPlugin,
  identityApiRef,
  configApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const metricsPlugin = createPlugin({
  id: 'metrics',
  routes: {
    entityContent: metricsRootRouteRef,
  },
  apis: [
    createApiFactory({
      api: metricsApiRef,
      deps: { configApi: configApiRef, identityApi: identityApiRef },
      factory({ configApi, identityApi }) {
        return new MetricsClient({ configApi, identityApi });
      },
    }),
  ],
});

export const Metrics = metricsPlugin.provide(
  createRoutableExtension({
    name: 'Metrics',

    component: () =>
      import('./components/Metrics').then(module => module.MetricsPage),

    mountPoint: metricsRootRouteRef,
  }),
);
export const Vulnerabilities = metricsPlugin.provide(
  createRoutableExtension({
    name: 'Vulnerabilities',

    component: () =>
      import('./components/Vulnerabilities').then(
        module => module.VulnerabilitiesPage,
      ),

    mountPoint: vulnerabilitiesRootRouteRef,
  }),
);
