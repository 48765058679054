// eslint-disable-next-line
import React, { ReactNode, useState } from 'react';
import { makeStyles, Typography, Collapse, Link } from '@material-ui/core';
import { DateRangeBar } from './DateRangeBar';
import { CurrentRangeDisplay } from './CurrentRangeDisplay';
import { InfoBar } from '../InfoBar';

import { BackstageTheme } from '@backstage/theme';
import { DateRanges } from '../../metrics';

type MetricsLineChartContainerProps = {
  heading: string;
  descriptionLead: string;
  descriptionBody: ReactNode;
  defaultDateRange?: DateRanges | undefined;
  children?: ReactNode;
};

const useStyles = makeStyles<BackstageTheme>(theme => ({
  metricsContainer: {
    minHeight: '23vw',
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(1.5)}px`,
    borderRadius: '4px',
  },
  expand: {
    display: 'inline-block',
    verticalAlign: 'baseline',
  },
  moreInfo: {
    marginTop: `${theme.spacing(2)}px`,
  },
}));

function MetricsChartContainer({
  heading,
  descriptionLead,
  descriptionBody,
  defaultDateRange,
  children,
}: MetricsLineChartContainerProps) {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.metricsContainer}>
      <Typography variant="h5">{heading}</Typography>

      <Typography variant="body2">
        {descriptionLead}{' '}
        <Link
          variant="body2"
          component="button"
          className={classes.expand}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          more
        </Link>
      </Typography>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.moreInfo}>{descriptionBody}</div>
      </Collapse>

      <InfoBar
        primary={<CurrentRangeDisplay />}
        secondary={<DateRangeBar defaultDateRange={defaultDateRange} />}
      />

      <div>{children}</div>
    </div>
  );
}

export { MetricsChartContainer };
