import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 90,
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 354 204"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M67.558 69l-5.222 15.191H48l11.588 7.985-4.412 13.722 12.382-7.97L80.042 106l-4.44-13.824L87 84.19H72.767z"
          fill="#9F1B32"
          fillRule="nonzero"
        />
        <path
          d="M195.996 125.37c.04 2.592-.223 5.19-.38 8.495h-13.28c0-3.306.188-6.39-.04-9.44-.453-5.968-3.053-10.394-9.629-10.421-6.463-.026-9.704 4.04-9.756 10.182-.094 11.065-.144 22.131-.152 33.197l-.148.002.174.003c.016 4.41.038 8.821.067 13.232.038 6.077 2.906 10.112 9.633 10.187 6.817.075 9.23-4.194 9.793-10.107.273-2.876.046-5.8.046-9.268H194.9c4.563 21.786-9.738 37.792-28.71 32.576-10.537-2.897-17.032-11.76-17.099-23.938-.023-4.122-.042-8.244-.057-12.367l-.035.002c-.001-10.827.028-21.654.09-32.48.082-14.655 9.642-24.943 23.085-25.22 13.541-.28 23.579 10.372 23.821 25.366zM135.248 121.153h-12.941c-.197-3.427-.22-6.778-.616-10.086-.72-6.018-3.697-10.245-10.285-9.852-6.49.387-9.021 4.706-9.215 10.873-.36 11.447 1.909 14.397 13.235 16.747 13.09 2.714 20.286 11.33 20.502 24.676.091 5.599.101 11.2-.002 16.797-.266 14.444-10.415 24.88-23.91 24.69-13.188-.186-23.025-10.336-23.555-24.368-.11-2.902-.016-5.813-.016-9.346h13.443c.181 3.317.15 6.718.6 10.051.76 5.645 3.64 9.608 9.833 9.485 6.081-.122 9.25-3.867 9.545-9.696.31-6.11.176-12.25.091-18.377-.105-7.597-5.526-9.995-11.662-10.625-18.271-1.874-25.207-19.6-21.195-37.11 2.6-11.344 12.4-18.411 23.417-17.995 11.524.435 20.604 8.19 22.613 20.025.736 4.33.118 8.887.118 14.11zM75 195H61v-79h14v79zM0 55c27.935 10.33 48.562 41.491 47.988 71.543C47.43 155.74 26.391 186.076 0 195V55zm15 29v82c22.842-22.177 22.49-65.027 0-82z"
          fill="#000"
          fillRule="nonzero"
        />
        <g transform="translate(206)">
          <g transform="translate(5)">
            <path
              fill="#9F1B32"
              fillRule="nonzero"
              d="M67.545 124.376h33v34.899h-33z"
            />
            <ellipse
              stroke="#000"
              strokeWidth="10"
              cx="59"
              cy="139.39"
              rx="59"
              ry="58.841"
            />
            <path
              stroke="#000"
              strokeWidth="5"
              d="M32.552 87.043v104.001M67.952 80.956v118.492M100.5 96.5v83M0 123.564h118M0 160.086h118M50.455 76.492c0-8.105 2.755-12.157 8.265-12.157 5.883-.315 8.825 3.737 8.825 12.157h-17.09zM59.5.5L59 64.724"
            />
          </g>
          <circle
            stroke="#000"
            strokeWidth="3"
            fill="#9F1B32"
            fillRule="nonzero"
            cx="140"
            cy="105"
            r="6"
          />
          <circle fill="#9F1B32" fillRule="nonzero" cx="126" cy="190" r="3" />
          <g stroke="#000" strokeWidth="3">
            <path d="M138.5 134.5V146M145 140.5h-13" />
          </g>
          <g stroke="#000" strokeWidth="3">
            <path d="M6 181v10.517M11.474 186.651H.764" />
          </g>
          <g stroke="#000" strokeWidth="3">
            <path d="M9.5 69.5v12M15.5 75.5h-12" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoFull;
