// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { MetricsChart } from '../MetricsChart';
import { dependencyVulnerabilitiesConfig } from './dependencyVulnerabilitiesConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { DependencyVulnerabilitiesResult } from '../../metrics';
import { useApi } from '@backstage/core-plugin-api';

type DescriptionProps = {
  repoName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ repoName }: DescriptionProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the number of open vulnerabilities in source code
        dependencies for the current services GitHub repository.
      </Typography>
      <Typography variant="body2" paragraph>
        The number decreases when the vulnerable libraries get patched. If there
        are no security updates available for a library, other remediations can
        be applied. In this case, the vulnerability alert can be dismissed from
        your services GitHub repository.
      </Typography>
      <Typography variant="body2" paragraph>
        False alerts are possible. For more detailed information visit your
        services GitHub{' '}
        <Link
          to={`https://github.com/pretamanger/${repoName}/security/dependabot`}
          target="_blank"
          rel="noopener"
        >
          dependabot alerts page{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};

const DependencyVulnerabilities = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);
  const entityName = entity?.metadata?.name;
  const gitHubProjectSlug =
    entity?.metadata?.annotations?.['github.com/project-slug'] ?? '';
  const gitHubRepoName = gitHubProjectSlug.split('/')[1];

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const { data: chartData, error } = useSWR(
    ['metrics-dependency-vulnerabilities', entityName, dateFrom, dateTo],
    () =>
      api.getDependencyVulnerabilities(gitHubRepoName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!gitHubRepoName) {
    return null;
  }

  return (
    <MetricsChart<DependencyVulnerabilitiesResult>
      heading="Open dependency vulnerabilities"
      descriptionLead="How many vulnerabilities in your services dependencies are currently open?"
      descriptionBody={<Description repoName={gitHubRepoName} />}
      chartData={chartData}
      error={error}
      configuration={dependencyVulnerabilitiesConfig}
      chartName={entityName}
      defaultDateRange="twoWeeksRange"
    />
  );
};

export { DependencyVulnerabilities };
