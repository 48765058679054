import { transformDeployments } from './transformDeployments';
import { pluralise } from '../../lib/pluralise';
import { format } from 'date-fns';
import { noDataMessagePlugin } from '../../lib/chart/noDataMessagePlugin';

import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { DeploymentsResult } from '../../metrics';
import { buildWeekAxisTickLabels } from '../../lib/chart/buildWeekAxisTickLabels';

function deploymentsConfig(
  chartData: DeploymentsResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformDeployments(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'line',
    data,
    plugins: [noDataMessage],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          title: {
            display: true,
            text: 'Week',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildWeekAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          title: {
            display: true,
            text: 'Count',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            color: palette.textSubtle,
            autoSkip: false,
            maxRotation: 0,
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            filter: legendItem => legendItem?.text !== 'SLO',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
          callbacks: {
            title: item => {
              const rawData: any = item?.[0]?.raw;
              const weekStarting = rawData?.x;
              const weekNumber = item?.[0]?.label?.replace(/,(.*)/, '');

              return `Week ${weekNumber} of ${format(
                weekStarting,
                'yyyy',
              )}, started on ${format(weekStarting, `EEEE do 'of' MMMM`)}`;
            },
            label: item => {
              const datasetLabel = item?.dataset?.label;
              const deploymentCount = item?.formattedValue;

              if (datasetLabel === 'SLO') {
                return ` Deployment target: ${deploymentCount} ${pluralise(
                  'deployment',
                  deploymentCount,
                )} or more a week`;
              }

              return ` ${deploymentCount} ${pluralise(
                'deployment',
                deploymentCount,
              )} completed`;
            },
            labelColor: (context): any => ({
              borderColor: context?.dataset?.borderColor,
              backgroundColor: context?.dataset?.backgroundColor,
              borderWidth: 2,
            }),
          },
        },
      },
    },
  };
}

export { deploymentsConfig };
