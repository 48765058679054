function pluralise(
  singularWord: string,
  countString: string,
  pluralisedWord?: string,
) {
  const count = parseInt(countString, 10);
  let result;

  if (count > 1) {
    if (pluralisedWord) {
      result = pluralisedWord;
    } else if (singularWord.match(/[^aeiou]y$/)) {
      result = singularWord.replace(/y$/, 'ies');
    } else {
      result = `${singularWord}s`;
    }
  } else {
    result = singularWord;
  }

  return result;
}

export { pluralise };
