/* eslint-disable */
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { MetricsChart } from '../MetricsChart';
import { containerVulnerabilitiesConfig } from './containerVulnerabilitiesConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { ContainerVulnerabilitiesResult } from '../../metrics';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const projectName = configApi.getString('metrics.projectName');

  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the number of open vulnerabilities in the underlying Docker
        image.
      </Typography>
      <Typography variant="body2" paragraph>
        The vulnerabilities may be mitigated by upgrading the vulnerable package
        or upgrading or changing the base image. Usually, using "alpine", "slim"
        or "distroless" versions of images decreases the number of
        vulnerabilities.
      </Typography>
      <Typography variant="body2" paragraph>
        More information about vulnerabilities and their severity may be found
        on the{' '}
        <Link
          to={`https://console.cloud.google.com/gcr/images/${projectName}/eu/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          GCP container registry console{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};

const ContainerVulnerabilities = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);
  const entityName = entity?.metadata?.name;
  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const { data: chartData, error } = useSWR(
    ['metrics-container-vulnerabilities', entityName, dateFrom, dateTo],
    () =>
      api.getContainerVulnerabilities(entityName, {
        dateFrom,
        dateTo,
      }),
  );

  return (
    <MetricsChart<ContainerVulnerabilitiesResult>
      heading="Open container vulnerabilities"
      descriptionLead="How many vulnerabilities are currently found in your container?"
      descriptionBody={<Description serviceName={entityName} />}
      chartData={chartData}
      error={error}
      configuration={containerVulnerabilitiesConfig}
      chartName={entityName}
      defaultDateRange="twoWeeksRange"
    />
  );
};

export { ContainerVulnerabilities };
