import React from 'react';
import { Content, Header, Page, CatalogIcon } from '@backstage/core-components';
import { Grid, Card, CardContent } from '@material-ui/core';
import {
  SearchBar,
  SearchResult,
  SearchFilter,
  SearchPagination,
} from '@backstage/plugin-search-react';
import { CatalogSearchResultListItem } from '@backstage/plugin-catalog';

export const searchPage = (
  <Page themeId="home">
    <Header title="Search" />
    <Content>
      <Grid container direction="row">
        <Grid item xs={12}>
          <SearchBar />
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <SearchFilter.Select
                name="kind"
                values={['Component', 'Template']}
              />
            </CardContent>
            <CardContent>
              <SearchFilter.Checkbox
                name="lifecycle"
                values={['experimental', 'production']}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <SearchPagination />
          <SearchResult>
            <CatalogSearchResultListItem icon={<CatalogIcon />} />
          </SearchResult>
        </Grid>
      </Grid>
    </Content>
  </Page>
);
