// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { last } from 'lodash';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { containerVulnerabilitiesSummaryConfig } from './containerVulnerabilitiesSummaryConfig';
import { ContainerVulnerabilitiesAggregatedResult } from '../../../metrics';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { formatISO, sub } from 'date-fns';
import { MetricsSummaryChart } from '../../MetricsSummaryChart';

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = () => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const projectName = configApi.getString('metrics.projectName');

  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the total number of open container vulnerabilities in a
        services underlying Docker image.
      </Typography>
      <Typography variant="body2" paragraph>
        The vulnerabilities may be mitigated by upgrading the vulnerable package
        or upgrading or changing the base image. Usually, using "alpine", "slim"
        or "distroless" versions of images decreases the number of
        vulnerabilities.
      </Typography>
      <Typography variant="body2" paragraph>
        More information about vulnerabilities and their severity may be found
        on the{' '}
        <Link
          to={`https://console.cloud.google.com/gcr/images/${projectName}`}
          target="_blank"
          rel="noopener"
        >
          GCP container registry console{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
        . For information per service please see the table below.
      </Typography>
    </>
  );
};

const ContainerVulnerabilitiesSummary = () => {
  const [total, setTotal] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const api = useApi(metricsApiRef);
  const today = formatISO(new Date(), { representation: 'date' });
  const dateFrom = formatISO(sub(new Date(), { weeks: 1 }), {
    representation: 'date',
  });
  const dateTo = today;

  const { data: chartData, error } = useSWR(
    ['metrics-container-vulnerabilities-aggregated', dateFrom, dateTo],
    () =>
      api.getContainerVulnerabilitiesAggregated({
        dateFrom,
        dateTo,
      }),
  );

  useEffect(
    function provideLoadingStatus() {
      if (!chartData && !error) {
        setIsLoading(true);
      }

      if (chartData || error) {
        setIsLoading(false);
      }
    },
    [chartData, error],
  );

  useEffect(
    function setTotalVulnerabilities() {
      const hasVulnerabilities =
        chartData?.openVulnerabilitiesAggregated.length ?? 0;

      if (hasVulnerabilities) {
        const latestAggregatedVulnerabilities = last(
          chartData?.openVulnerabilitiesAggregated,
        );

        setTotal(latestAggregatedVulnerabilities?.vulnerability_count);
      }
    },
    [chartData],
  );

  const CurrentOpenVulnerabilitiesMessage = () => (
    <Typography variant="body2" color="textSecondary">
      {isLoading ? '' : `${total} open vulnerabilities`}
    </Typography>
  );

  return (
    <MetricsSummaryChart<ContainerVulnerabilitiesAggregatedResult>
      heading="Open vulnerabilities"
      descriptionLead="How many vulnerabilities are open in service containers?"
      descriptionBody={<Description />}
      chartData={chartData}
      error={error}
      configuration={containerVulnerabilitiesSummaryConfig}
      chartName="aggregated"
      currentValueMessage={<CurrentOpenVulnerabilitiesMessage />}
      dataTestId="open-container-vulnerabilities-summary-chart"
    />
  );
};

export { ContainerVulnerabilitiesSummary };
