// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { metricsApiRef } from '../../api';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { MetricsChart } from '../MetricsChart';
import { containerWindowOfExposureConfig } from './containerWindowOfExposureConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { ContainerWindowOfExposureResult } from '../../metrics';

import { configApiRef, useApi } from '@backstage/core-plugin-api';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();
  const configApi = useApi(configApiRef);
  const projectName = configApi.getString('metrics.projectName');

  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the number of days since the vulnerability was discovered in
        the container or the number of days since a vulnerable container was
        first used. The number will rise every day for every container
        vulnerability that has not been resolved.
      </Typography>
      <Typography variant="body2" paragraph>
        More information about vulnerabilities and their severity may be found
        on the{' '}
        <Link
          to={`https://console.cloud.google.com/gcr/images/${projectName}/eu/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          GCP container registry console{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};
const ContainerWindowOfExposure = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);

  const entityName = entity?.metadata?.name;

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const { data: chartData, error } = useSWR(
    ['metrics-container-window-of-exposure', entityName, dateFrom, dateTo],
    () =>
      api.getContainerWindowOfExposure(entityName, {
        dateFrom,
        dateTo,
      }),
  );

  return (
    <MetricsChart<ContainerWindowOfExposureResult>
      heading="Container window of exposure"
      descriptionLead="How long is your service exposed due to the vulnerabilities in your container?"
      descriptionBody={<Description serviceName={entityName} />}
      chartData={chartData}
      error={error}
      configuration={containerWindowOfExposureConfig}
      chartName={entityName}
    />
  );
};

export { ContainerWindowOfExposure };
