import { Tick } from 'chart.js';

/**
 * Display week axis labels. For the first tick on the axis provide week and year to display a tick:
 *
 *   05   06  07  52  01
 *  2021             2022
 *
 * When the previous week is higher than the current week, denoting a change of year,
 * also display week and year. Otherwise just display week.
 *
 * @param ticks
 */
function buildWeekAxisTickLabels(ticks: Tick[]) {
  let currentYear: string;

  return ticks.map((tick, index) => {
    if (Array.isArray(tick?.label)) {
      const [week_number, year]: string[] = tick?.label;

      if (index === 0 && year) {
        currentYear = year;

        return {
          ...tick,
          label: [week_number, year],
        };
      }

      if (String(year) !== String(currentYear)) {
        currentYear = year;

        return {
          ...tick,
          label: [week_number, year],
        };
      }

      return {
        ...tick,
        label: week_number,
      };
    }

    return tick;
  });
}

export { buildWeekAxisTickLabels };
