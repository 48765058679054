import { format } from 'date-fns';
import { transformServiceAvailability } from './transformServiceAvailability';
import { buildDateAxisTickLabels } from '../../lib/chart/buildDateAxisTickLabels';
import { noDataMessagePlugin } from '../../lib/chart/noDataMessagePlugin';

import { ChartConfiguration } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';
import { ServiceAvailabilityResult } from '../../metrics';

function serviceAvailabilityConfig(
  chartData: ServiceAvailabilityResult,
  palette: BackstagePalette,
): ChartConfiguration {
  const data = transformServiceAvailability(chartData);
  const noDataMessage = noDataMessagePlugin(palette);

  return {
    type: 'line',
    data,
    plugins: [noDataMessage],
    options: {
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          display: true,
          beginAtZero: true,
          ticks: {
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
          },
          beforeFit: scale => {
            scale.ticks = buildDateAxisTickLabels(scale?.ticks);
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          title: {
            display: true,
            text: 'Availability',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
          ticks: {
            precision: 0,
            color: palette.textSubtle,
            autoSkip: true,
            maxRotation: 0,
            callback: value => `${value}%`,
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            filter: legendItem => legendItem?.text !== 'SLO',
            color: palette.textContrast,
            font: {
              size: 16,
            },
          },
        },
        tooltip: {
          itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
          callbacks: {
            title: items => {
              const rawData: any = items?.[1]?.raw; // use a dataset that is not the target dataset
              const { x: startDate } = rawData;
              return format(startDate, `EEEE do 'of' LLLL yyyy`);
            },
            label: item => {
              const datasetLabel = item?.dataset?.label;
              const serviceAvailability = item?.formattedValue;

              if (datasetLabel === 'SLO') {
                return ` Service availability target: Service is available ${parseFloat(
                  parseFloat(serviceAvailability).toFixed(4),
                )}% of the time`;
              }

              return ` ${datasetLabel} service availability was ${parseFloat(
                parseFloat(serviceAvailability).toFixed(4),
              )}%`;
            },
            labelColor: (context): any => ({
              borderColor: context?.dataset?.borderColor,
              backgroundColor: context?.dataset?.backgroundColor,
              borderWidth: 2,
            }),
          },
        },
      },
    },
  };
}

export { serviceAvailabilityConfig };
