// eslint-disable-next-line
import React from 'react';
import useSWR from 'swr';
import { useEntity } from '@backstage/plugin-catalog-react';
import { makeStyles, Typography } from '@material-ui/core';
import { metricsApiRef } from '../../api';

import { MetricsChart } from '../MetricsChart';
import { leadTimeConfig } from './leadTimeConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { LeadTimeResult } from '../../metrics';

import { useApi } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the time it takes from commit (when work is ready) to work
        deployed into production (work is producing value).
      </Typography>
      <Typography variant="body2" paragraph>
        Inclusive of peer review (if not pairing/mobbing) and time in the build
        & deploy pipeline. Not inclusive of time from idea formation to produce
        the work/commit.
      </Typography>
      <Typography variant="body2" paragraph>
        High lead times indicate flow of work through team is blocked and team
        ways of working need to change, or service context/operational knowledge
        needs to be built up.
      </Typography>
      <Typography variant="body2" paragraph>
        For more detailed information on your service level objectives, visit
        your services{' '}
        <Link
          to={`https://console.cloud.google.com/monitoring/services/237652659/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          monitoring page{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};

const LeadTime = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);

  const entityName = entity?.metadata?.name;
  const serviceName =
    entity?.metadata?.annotations?.['gcp/service-application-name'] ?? '';

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const { data: chartData, error } = useSWR(
    ['metrics-leadtime', entityName, dateFrom, dateTo],
    () =>
      api.getLeadTime(serviceName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!serviceName) {
    return null;
  }

  return (
    <MetricsChart<LeadTimeResult>
      heading="Delivery lead time"
      descriptionLead="How long does it take to get work into production?"
      descriptionBody={<Description serviceName={serviceName} />}
      chartData={chartData}
      error={error}
      configuration={leadTimeConfig}
      chartName={entityName}
    />
  );
};

export { LeadTime };
