// eslint-disable-next-line
import { useEffect, useState } from 'react';
import { formatISO } from 'date-fns';
import { useApi } from '@backstage/core-plugin-api';
import { metricsApiRef } from '../api';
import useSWR from 'swr';
import { useEntityList } from '@backstage/plugin-catalog-react';
import {
  ServiceDetails,
  ContainerVulnerabilitiesServiceList,
  ContainerVulnerabilitiesServiceListWithEntity,
} from '../metrics';
import { Entity } from '@backstage/catalog-model';
import { entityToGitHubRepositoryMap } from './entityToGitHubRepositoryMap';

function useContainerVulnerabilitiesSummary() {
  const [chartData, setChartData] = useState<
    ContainerVulnerabilitiesServiceListWithEntity[]
  >([]);
  const api = useApi(metricsApiRef);
  const today = formatISO(new Date(), { representation: 'date' });
  const dateFrom = today;
  const dateTo = today;

  const { data: metricsApiData, error: metricsApiError } = useSWR(
    ['metrics-container-vulnerabilities', dateFrom, dateTo],
    () =>
      api.getContainerVulnerabilitiesServiceList({
        dateFrom,
        dateTo,
      }),
  );

  const {
    entities,
    loading: entitiesLoading,
    error: entitiesError,
  } = useEntityList();

  useEffect(
    function transformChartData() {
      if (metricsApiData?.serviceList && entities) {
        const chartDataWithEntity: ContainerVulnerabilitiesServiceListWithEntity[] =
          entities
            .reduce(
              (
                entityAccumulator: ServiceDetails[],
                entity: Entity,
              ): ServiceDetails[] => [
                ...entityAccumulator,
                {
                  name: entity?.metadata?.name,
                  owner: entity?.spec?.owner,
                },
              ],
              [],
            )
            .map(
              (
                serviceDetails: ServiceDetails,
              ): ContainerVulnerabilitiesServiceListWithEntity | undefined => {
                const serviceAggregatedVulnerabilities:
                  | ContainerVulnerabilitiesServiceList
                  | undefined = metricsApiData?.serviceList.find(
                  containerVulnerability =>
                    entityToGitHubRepositoryMap(serviceDetails?.name) ===
                    containerVulnerability?.service_name,
                );

                if (serviceAggregatedVulnerabilities) {
                  const owner = String(serviceDetails?.owner) ?? 'no-owner';
                  const name = serviceDetails?.name ?? 'no-name';

                  return {
                    ...serviceAggregatedVulnerabilities,
                    id: `${name}-${owner}`,
                    owner,
                    name,
                  };
                }

                return undefined;
              },
            )
            .filter(Boolean);

        setChartData(chartDataWithEntity);
      }
    },
    [metricsApiData, entities],
  );

  return {
    isLoading:
      entitiesLoading && !entitiesError && !metricsApiData && !metricsApiError,
    chartData,
    metricsApiError,
    entitiesError,
  };
}

export { useContainerVulnerabilitiesSummary };
