import { commonDatasetOptions } from '../../../lib/chart/commonDatasetOptions';
import {
  DependencyVulnerabilitiesAggregated,
  DependencyVulnerabilitiesAggregatedResult,
} from '../../../metrics';
import { ChartData } from 'chart.js';
import { commonBarChartOptions } from '../../../lib/chart/commonBarChartOptions';
import { buildSummaryDateDataConfig } from '../../../lib/chart/buildSummaryDateDataConfig';

const criticalVulnerabilitiesRgbColour = '188,48,35';
const highVulnerabilitiesRgbColour = '238,110,43';
const moderateVulnerabilitiesRgbColour = '222,190,73';
const lowVulnerabilitiesRgbColour = '47,170,212';

function provideHighlights(
  defaultColour: string,
  tonedDownOpacity: string,
  dataLength: number,
) {
  return Array.from({ length: dataLength }, (_value, index) => {
    if (index < dataLength - 1) {
      return defaultColour.replace(/(\d\.?\d?\))$/g, tonedDownOpacity);
    }

    return defaultColour;
  });
}

function transformDependencyVulnerabilitiesSummary({
  openVulnerabilitiesAggregated,
}: DependencyVulnerabilitiesAggregatedResult): ChartData {
  return buildSummaryDateDataConfig<DependencyVulnerabilitiesAggregated>(
    openVulnerabilitiesAggregated,
    [
      transformAggregatedLowVulnerabilities,
      transformAggregatedModerateVulnerabilities,
      transformAggregatedHighVulnerabilities,
      transformAggregatedCriticalVulnerabilities,
    ],
  );
}

function transformAggregatedCriticalVulnerabilities(
  openVulnerabilitiesAggregated: DependencyVulnerabilitiesAggregated[],
) {
  const data = openVulnerabilitiesAggregated.map(
    ({ iso_date, critical_count }: DependencyVulnerabilitiesAggregated) => {
      return {
        x: new Date(iso_date),
        y: critical_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Critical',
    backgroundColor: provideHighlights(
      `rgba(${criticalVulnerabilitiesRgbColour},0.7)`,
      '0.2',
      data.length,
    ),
    hoverBackgroundColor: `rgba(${criticalVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${criticalVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${criticalVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformAggregatedHighVulnerabilities(
  openVulnerabilitiesAggregated: DependencyVulnerabilitiesAggregated[],
) {
  const data = openVulnerabilitiesAggregated.map(
    ({ iso_date, high_count }: DependencyVulnerabilitiesAggregated) => {
      return {
        x: new Date(iso_date),
        y: high_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'High',
    backgroundColor: provideHighlights(
      `rgba(${highVulnerabilitiesRgbColour},0.7)`,
      '0.2',
      data.length,
    ),
    hoverBackgroundColor: `rgba(${highVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${highVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${highVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformAggregatedModerateVulnerabilities(
  openVulnerabilitiesAggregated: DependencyVulnerabilitiesAggregated[],
) {
  const data = openVulnerabilitiesAggregated.map(
    ({ iso_date, moderate_count }: DependencyVulnerabilitiesAggregated) => {
      return {
        x: new Date(iso_date),
        y: moderate_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Moderate',
    backgroundColor: provideHighlights(
      `rgba(${moderateVulnerabilitiesRgbColour},0.7)`,
      '0.2',
      data.length,
    ),
    hoverBackgroundColor: `rgba(${moderateVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${moderateVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${moderateVulnerabilitiesRgbColour},1)`,
    data,
  };
}

function transformAggregatedLowVulnerabilities(
  openVulnerabilitiesAggregated: DependencyVulnerabilitiesAggregated[],
) {
  const data = openVulnerabilitiesAggregated.map(
    ({ iso_date, low_count }: DependencyVulnerabilitiesAggregated) => {
      return {
        x: new Date(iso_date),
        y: low_count,
      };
    },
  );

  return {
    ...commonDatasetOptions,
    ...commonBarChartOptions,
    label: 'Low',
    backgroundColor: provideHighlights(
      `rgba(${lowVulnerabilitiesRgbColour},0.7)`,
      '0.2',
      data.length,
    ),
    hoverBackgroundColor: `rgba(${lowVulnerabilitiesRgbColour},0.7)`,
    pointBorderColor: `rgba(${lowVulnerabilitiesRgbColour},1)`,
    pointHoverBackgroundColor: `rgba(${lowVulnerabilitiesRgbColour},1)`,
    data,
  };
}

export { transformDependencyVulnerabilitiesSummary };
