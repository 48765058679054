import { commonDatasetOptions } from '../../../lib/chart/commonDatasetOptions';
import {
  DependencyWindowOfExposureAggregatedResult,
  DependencyWindowOfExposureAggregated,
} from '../../../metrics';
import { ChartData, ScriptableLineSegmentContext } from 'chart.js';
import { calculateWindowOfExposure } from '../../../lib/chart/calculateWindowOfExposure';
import { buildSummaryDateDataConfig } from '../../../lib/chart/buildSummaryDateDataConfig';

const greyRgbColour = '220,220,220';
const meanRgbColour = '173,209,75';
const meanRgbBorderColour = '152,184,66';
const percentile95RgbColour = '47,170,212';
const percentile95RgbBorderColour = '41,150,185';

/**
 * Provide highlight styles for line chart segments. This tones down the styles for all the entries apart
 * from the last day shown. The result is the last day is highlighted in the chart.
 * Of note "Currently all of the border* and backgroundColor options are supported" only via segments
 * https://www.chartjs.org/docs/3.3.0/charts/line.html#segment
 *
 * @param chartContext
 * @param value
 * @param dataLength
 */
function provideSegmentHighlights(
  chartContext: ScriptableLineSegmentContext,
  value: string,
  dataLength: number,
) {
  const point0Index = chartContext.p0DataIndex;
  const point1Index = chartContext.p1DataIndex;

  if (point0Index === dataLength - 2 && point1Index === dataLength - 1) {
    return undefined;
  }

  return value;
}

/**
 * Provide highlight styles for line charts. This tones down the styles for all the entries apart
 * from the last day shown. The result is the last day is highlighted in the chart
 *
 * @param defaultColour
 * @param tonedDownOpacity
 * @param dataLength
 */
function provideHighlights(
  defaultColour: string,
  tonedDownOpacity: string,
  dataLength: number,
) {
  return Array.from({ length: dataLength }, (_value, index) => {
    if (index < dataLength - 2) {
      return defaultColour.replace(/(\d\.?\d?\))$/g, tonedDownOpacity);
    }

    return defaultColour;
  });
}

function transformDependencyWindowOfExposureSummary({
  windowOfExposureAggregated,
}: DependencyWindowOfExposureAggregatedResult): ChartData {
  return buildSummaryDateDataConfig<DependencyWindowOfExposureAggregated>(
    windowOfExposureAggregated,
    [transformMean, transformPercentile95],
  );
}

function transformMean(
  windowOfExposureAggregated: DependencyWindowOfExposureAggregated[],
) {
  const data = windowOfExposureAggregated.map(
    ({ iso_date, mean_milliseconds }: DependencyWindowOfExposureAggregated) => {
      return {
        x: new Date(iso_date),
        y: calculateWindowOfExposure(mean_milliseconds),
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: 'Average',
    backgroundColor: `rgba(${meanRgbColour},0.3)`,
    borderColor: `rgba(${meanRgbBorderColour},1)`,
    pointBorderColor: `rgba(${meanRgbColour},0.1)`,
    pointHoverBackgroundColor: `rgba(${meanRgbColour},1)`,
    pointBackgroundColor: provideHighlights(
      `rgba(${greyRgbColour},1)`,
      '0.2',
      data.length,
    ),
    segment: {
      backgroundColor: (chartContext: ScriptableLineSegmentContext) =>
        provideSegmentHighlights(
          chartContext,
          `rgba(${meanRgbColour},0.1)`,
          data.length,
        ),
      borderColor: (chartContext: ScriptableLineSegmentContext) =>
        provideSegmentHighlights(
          chartContext,
          `rgba(${meanRgbBorderColour},0.1)`,
          data.length,
        ),
    },
    data,
  };
}

function transformPercentile95(
  windowOfExposureAggregated: DependencyWindowOfExposureAggregated[],
) {
  const data = windowOfExposureAggregated.map(
    ({ iso_date, percentile_95_milliseconds }) => {
      return {
        x: new Date(iso_date),
        y: calculateWindowOfExposure(percentile_95_milliseconds),
      };
    },
  );

  return {
    ...commonDatasetOptions,
    label: '95%',
    backgroundColor: `rgba(${percentile95RgbColour},0.3)`,
    borderColor: `rgba(${percentile95RgbBorderColour},1)`,
    pointBorderColor: `rgba(${percentile95RgbColour},0.1)`,
    pointHoverBackgroundColor: `rgba(${percentile95RgbColour},1)`,
    pointBackgroundColor: provideHighlights(
      `rgba(${greyRgbColour},1)`,
      '0.2',
      data.length,
    ),
    segment: {
      backgroundColor: (chartContext: ScriptableLineSegmentContext) =>
        provideSegmentHighlights(
          chartContext,
          `rgba(${percentile95RgbColour},0.1)`,
          data.length,
        ),
      borderColor: (chartContext: ScriptableLineSegmentContext) =>
        provideSegmentHighlights(
          chartContext,
          `rgba(${percentile95RgbBorderColour},0.1)`,
          data.length,
        ),
    },
    data,
  };
}
export { transformDependencyWindowOfExposureSummary };
