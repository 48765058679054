import { Chart } from 'chart.js';
import { BackstagePalette } from '@backstage/theme';

function noDataMessagePlugin(palette: BackstagePalette) {
  return {
    id: 'no-data-message',
    afterDraw: function afterDraw(chart: Chart) {
      if (chart?.data?.datasets?.[0]?.data?.length === 0) {
        const chartCtx = chart?.ctx;
        const width = chart?.ctx?.canvas?.width;
        const height = chart?.ctx?.canvas?.height;
        const scale = window.devicePixelRatio;

        if (chartCtx) {
          chartCtx.save();
          chartCtx.textAlign = 'center';
          chartCtx.textBaseline = 'middle';
          chartCtx.font = "'niveau-grotesk, Interstate, sans-serif'";
          chartCtx.fillStyle = palette.textContrast;
          chartCtx.fillText(
            'No data available',
            width / (2 * scale),
            height / (3 * scale),
          );
          chartCtx.restore();
        }
      }
    },
  };
}

export { noDataMessagePlugin };
