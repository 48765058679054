/* eslint-disable @backstage/no-forbidden-package-imports */
// This file is from the BackStage default-app
// Its important to keep this file up to date with any changes in https://github.com/backstage/backstage/tree/master/packages/create-app/templates/default-app

import React, { ReactNode, useMemo, useState } from 'react';
import BadgeIcon from '@material-ui/icons/CallToAction';
import { Grid, Typography } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  EntityDependsOnResourcesCard,
  EntityDependsOnComponentsCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  Deployments,
  LeadTime,
  isMetricsAvailable,
  hasAnnotation,
  MeanTimeToResolution,
  Incident,
  ServiceAvailability,
  ServiceLatency,
  isVulnerabilitiesAvailable,
  DependencyVulnerabilities,
  ContainerVulnerabilities,
  DependencyWindowOfExposure,
  ContainerWindowOfExposure,
} from '@internal/plugin-metrics';
import { ChartDateRangesProvider } from '@internal/plugin-metrics/src/hooks/useChartDateRanges';
import { EntityBadgesDialog } from '@backstage/plugin-badges';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => {
          throw new Error(`Expected error.`);
          // setBadgesDialogOpen(true)
        },
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout UNSTABLE_extraContextMenuItems={extraMenuItems}>
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <Grid item md={8} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={4} xs={12}>
      <EntityLinksCard />
    </Grid>

    <Grid item md={8} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>

    <Grid item md={8} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
  </Grid>
);

const metricsContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntitySwitch>
      <EntitySwitch.Case if={entity => !Boolean(isMetricsAvailable(entity))}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">No metrics</Typography>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case
        if={entity =>
          Boolean(hasAnnotation(entity, 'gcp/service-application-name'))
        }
      >
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="deployments-metric">
            <Deployments />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="lead-time-metric">
            <LeadTime />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="service-availability-metric">
            <ServiceAvailability />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="service-latency-metric">
            <ServiceLatency />
          </ChartDateRangesProvider>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case
        if={entity => Boolean(hasAnnotation(entity, 'pagerduty.com/service'))}
      >
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="incident-metric">
            <Incident />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="mean-time-to-resolution-metric">
            <MeanTimeToResolution />
          </ChartDateRangesProvider>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const vulnerabilitiesContent = (
  <Grid container spacing={3} alignItems="stretch">
    <EntitySwitch>
      <EntitySwitch.Case
        if={entity => !Boolean(isVulnerabilitiesAvailable(entity))}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h5">
            No vulnerability metrics available
          </Typography>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case
        if={entity => Boolean(isVulnerabilitiesAvailable(entity))}
      >
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="dependency-vulnerabilities-metric">
            <DependencyVulnerabilities />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="dependency-window-of-exposure-metric">
            <DependencyWindowOfExposure />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="container-vulnerabilities-metric">
            <ContainerVulnerabilities />
          </ChartDateRangesProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartDateRangesProvider key="container-window-of-exposure-metric">
            <ContainerWindowOfExposure />
          </ChartDateRangesProvider>
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/vulnerabilities" title="Vulnerabilities">
      {vulnerabilitiesContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/vulnerabilities" title="Vulnerabilities">
      {vulnerabilitiesContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/metrics" title="Metrics">
      {metricsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/vulnerabilities" title="Vulnerabilities">
      {vulnerabilitiesContent}
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')}>{apiPage}</EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
