/* eslint-disable */
import React from 'react';
import useSWR from 'swr';
import { makeStyles, Typography } from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { metricsApiRef } from '../../api';
import { MetricsChart } from '../MetricsChart';
import { serviceLatencyConfig } from './serviceLatencyConfig';
import { useChartDateRanges } from '../../hooks/useChartDateRanges';
import { ServiceLatencyResult } from '../../metrics';
import { useApi } from '@backstage/core-plugin-api';

type DescriptionProps = {
  serviceName: string;
};

const useStyles = makeStyles({
  newPageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

const Description = ({ serviceName }: DescriptionProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2" paragraph>
        Defined as the duration of a request from the perspective of the
        requester, and is measured in % of time periods during a day where a
        percentage of requests met the latency target.
      </Typography>
      <Typography variant="body2" paragraph>
        High latency for any single request indicates a slow user experience,
        multiple requests with high latency compound to a very slow experience.
      </Typography>
      <Typography variant="body2" paragraph>
        For more detailed information on your service level objectives, visit
        your services{' '}
        <Link
          to={`https://console.cloud.google.com/monitoring/services/237652659/${serviceName}`}
          target="_blank"
          rel="noopener"
        >
          monitoring page{' '}
          <OpenInNewIcon className={classes.newPageIcon} fontSize="small" />
        </Link>
      </Typography>
    </>
  );
};

const ServiceLatency = () => {
  const { entity } = useEntity();
  const api = useApi(metricsApiRef);

  const { state } = useChartDateRanges();
  const dateFrom = state?.dateFrom;
  const dateTo = state?.dateTo;

  const entityName = entity?.metadata?.name;
  const serviceName =
    entity?.metadata?.annotations?.['gcp/service-application-name'] ?? '';

  const { data: chartData, error } = useSWR(
    ['metrics-service-latency', entityName, dateFrom, dateTo],
    () =>
      api.getServiceLatency(serviceName, {
        dateFrom,
        dateTo,
      }),
  );

  if (!serviceName) {
    return null;
  }

  return (
    <MetricsChart<ServiceLatencyResult>
      heading="Service latency"
      descriptionLead="How quickly does a service respond to a request?"
      descriptionBody={<Description serviceName={serviceName} />}
      chartData={chartData}
      error={error}
      configuration={serviceLatencyConfig}
      chartName={entityName}
    />
  );
};

export { ServiceLatency };
